.footer__top {
  width: 100%;

  padding: 10rem 20rem;
  background-color: $color-lightGray;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.footer__logo {
  width: 100%;
}

.footer__logo__image {
  height: 4rem;

  @include media('<=phone') {
    height: 24px;
  }
}

.footer__name {
  margin-top: 8rem;

  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;

  @include media('<=phone') {
    margin-top: 40px;
    font-size: 18px;
    text-align: left;
  }
}

.footer__office {
  margin-top: 8rem;

  @include media('<=phone') {
    margin-top: 40px;
  }
}

.footer__office__item {
  letter-spacing: 1px;

  @include media('<=phone') {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.footer__office__item__name {
  font-size: 1.7rem;
  font-weight: 700; 

  @include media('<=phone') {
    font-size: 16px;
  }
}

.footer__office__item__border {
  width: 95%;
  height: 1px;
  background-color: $color-black;
  opacity: 0.3;
  margin-top:0.8rem;

  @include media('<=phone') {
    margin-top: 8px;
  }
}

.footer__office__item__post {
  margin-top: 4rem;

  font-size: 1.5rem;

  @include media('<=phone') {
    margin-top: 16px;
    font-size: 13px;
  }
}

.footer__office__item__address {
  display: flex;
  align-items: baseline;
  letter-spacing: 0.12rem;

  @include media('<=phone') {
    margin-top: 8px;
  }
}

.footer__office__item__address__text {
  font-size: 1.5rem;
  line-height: 2.4rem;

  @include media('<=phone') {
    font-size: 14px;
    line-height: 24px;
  }
}

.footer__office__item__address__icon {
  height: 2.1rem;
  margin-left: 1.6rem;

  @include media('<=phone') {
    height: 16px;
    margin-left: 16px;
  }
}

.footer__office__item__tel {
  margin-top: 0.2rem;
  font-size: 1.5rem;

  @include media('<=phone') {
    margin-top: 8px;
    font-size: 14px;
  }
}

.footer__office__item__fax  {
  margin-top: 0.8rem;
  font-size: 1.5rem;

  @include media('<=phone') {
    margin-top: 8px;
    font-size: 14px;
  }
}

.footer__bottom  {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 0; 

  background-color: $color-black;
  color: $color-white;

  @include media('<=phone') {
    display: block;
    padding: 20px 16px;
  }
}

.footer__bottom__menu {
  display: flex;
  justify-content: center;

  @include media('<=phone') {
    flex-direction: column;
  }
}

.footer__bottom__menu__item {
  margin-left: 3.6rem;
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }

  @include media('<=phone') {
    font-size: 14px;
    margin-left: 0;
    margin-top: 30px;
  }
}

.footer__bottom__menu__item__text {
  color: $color-white;

  &:hover {
    opacity: 1;
    text-decoration: none;
    color: $color-lightGreen;
  }

  @include media('<=phone') {
    display: inline-block;
    font-size: 14px;
    margin-left: 0;
  }
}

.footer__bottom__border  {
  width: 82rem;
  height: 1px;

  margin-top: 2rem;

  background-color: $color-white;
  opacity: 0.3;

  @include media('<=phone') {
    width: 100%;
    margin-top: 30px;
  }
}

.footer__bottom__border__caption  {
  margin-top: 2rem;

  text-align: center;
  font-size: 1.3rem;
  letter-spacing: 0.2;

  @include media('<=phone') {
    width: 100%;
    margin-top: 30px;
    font-size: 12px;
    text-align: left;
  }
}

.footer__bottom__pageup {
  position: absolute;
  bottom: 3rem;
  right: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 6.8rem;
  background-color: $color-lightGreen;
  border-radius: 50%;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.9;
    transform: scale(0.99);
  }

  @include media('<=phone') {
    bottom: 40px;
    right: 16px;
    width: 60px;
    height: 60px;
  }
}

.footer__bottom__pageup__icon {
  width: 5rem;

  @include media('<=phone') {
    width: 40px;
  }
}

