.company__hero {
  display:flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60vh;
  margin-bottom: 6rem;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%,rgba(51, 51, 51, 0.2) 100%), url('../images/company_main.jpg');
  background-position: center;
  background-size: cover;

  @include media('<=phone') {
    height: 70vh;
  }
}

.company__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.company__hero__main {
  @extend %page-title;
}

.company__hero__sub {
  @extend %page-title-caption;
  margin-top: 1.6rem;
}

.company__message {
  @extend %section;
  padding: 6rem 35rem;
  text-align: center;

  @include media('<=phone') {
    padding: 60px 16px;
  }
}

.company__message__title__main {
  @extend %section-title;
}

.company__message__title__sub {
  @extend %section-subtitle;
}

.company__message__description {
  text-align: left;
  margin-top: 6rem;
  font-size: 2.7rem;
  font-weight: 700;

  letter-spacing: 0.4rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 18px;
    margin-top: 30px;
  }
}

.company__message__post {
  text-align: right;

  margin-top: 4rem;
  font-size: 1.7rem;

  @include media('<=phone') {
    font-size: 14px;
    margin-top: 20px;
  }
}

.company__message__name {
  text-align: right;

  margin-top: 1.6rem;
  font-size: 2.4rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 18px;
    margin-top: 14px;
  }
}

.company__overview {
  @extend %section;
  padding: 10rem 24rem;
  text-align: center;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.company__overview__title__main {
  @extend %section-title;
}

.company__overview__title__sub {
  @extend %section-subtitle;
}

.company__overview__list {
  @extend %contents;
  width: 100%;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.company__overview__item {
  padding: 2.4rem;
  margin: 0;

  &:nth-child(even) {
    background-color: $color-lightGray;
  }

  &:nth-child(odd) {
    background-color: $color-gray;
  }

  @include media('<=phone') {
    padding: 12px;
  }
}

.company__overview__item__title {
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.company__overview__item__description {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.company__access {
  @extend %section;
  text-align: center;

  background-color: $color-lightGray;
}

.company__access__title__main {
  @extend %section-title;
}

.company__access__title__sub {
  @extend %section-subtitle;
}

.company__access__office {
  @extend %contents;
  width: 100%;
  margin: 5rem 0 0 0;

  @include media('<=phone') {
    margin-top: 30px;
  }
}

.company__access__office__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
  margin-left: 2.4rem;

  &:first-child {
    margin-left: 0;
  }

  @include media('<=phone') {
    margin-top: 40px;
    margin-left: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.company__access__office__item__name {
  font-size: 2rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 16px;
  }
}

.company__access__office__item__map {
  margin-top: 4rem;
  width: 100%;
  height: 35vh;

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.company__access__office__button {
  @extend %buttonHoverToGreen;
  width: 30rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 4rem;
  color: $color-black;

  background-color: $color-white;

  @include media('<=phone') {
    margin-top: 20px;
    width: 100%;
    padding: 18px 16px;
    color: $color-white;
  }
}

.company__access__office__button__text {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;

  left: 50%;
  transform: translateX(-50%);

  @include media('<=phone') {
    font-size: 13px;
  }
}

.company__access__office__button__icon {
  position: absolute;
  width: 1.6rem;
  height: auto;

  right: 2rem;

  @include media('<=phone') {
    width: 10px;
    right: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .company__access__office__button__icon {
    top: 0.4rem;
  }
}

.company__qualification {
  @extend %section;
  text-align: center;
}

.company__qualification__title__main {
  @extend %section-title;
}

.company__qualification__title__sub {
  @extend %section-subtitle;
}

.company__qualification__list {
  @extend %contents;
  width: 100%;

  @include media('<=phone') {
    margin: 30px 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.company__qualification__list__items {
  width: 100%;

  @include media('<=phone') {
    padding: 0;
    margin-top: 30px;
  }
}

.company__qualification__list__item {
  padding: 1.6rem 2.4rem;
  margin: 0;

  &:nth-child(even) {
    background-color: $color-lightGray;
  }

  &:nth-child(odd) {
    background-color: $color-gray;
  }

  &:first-child {
    font-weight: 700;
    padding: 1.4rem 2.4rem;
    background-color: $color-extralightGreen;
  }
}

.company__qualification__list__item__name {
  padding: 0;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 13px;
  }
}

.company__qualification__list__item__number {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 13px;
  }
}

.company__history {
  @extend %section;
  padding: 10rem 24rem;
  text-align: center;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.company__history__title__main {
  @extend %section-title;
}

.company__history__title__sub {
  @extend %section-subtitle;
}

.company__history__list {
  @extend %contents;
  width: 100%;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.company__history__item {
  padding: 2.4rem;
  margin: 0;

  &:nth-child(even) {
    background-color: $color-lightGray;
  }

  &:nth-child(odd) {
    background-color: $color-gray;
  }

  @include media('<=phone') {
    padding: 12px;
  }
}

.company__history__item__title {
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;

  @include media('<=phone') {
    font-size: 12px;
  }
}

.company__history__item__description {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 14px;
  }
}