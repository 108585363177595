.employee__hero {
  display:flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%,rgba(51, 51, 51, 0.2) 100%), url('../images/employee_main.jpg');
  background-position: center;
  background-size: cover;

  @include media('<=phone') {
    height: 70vh;
  }
}

.employee__hero__title {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.employee__hero__main {
  @extend %page-title;
}

.employee__hero__sub {
  @extend %page-title-caption;
  margin-top: 1.6rem;
}


.employee__contents__wrapper {
  width: 100%;
  margin: 0;
}


.employee__column-side {
  background-color: rgba(51, 51, 51, 0.1);
  margin: 0;
}

.employee__anchor-menu {
  padding: 6rem 10rem;

  @include media('<=phone') {
    padding: 30px 16px;
  }
}

.employee__anchor-menu__title {
  font-size: 2rem;
  font-weight: 700;
  color: $color-lightGreen;

  @include media('<=phone') {
    font-size: 18px;
  }
}

.employee__anchor-menu__list {
  margin-top: 3rem;

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.employee__anchor-menu__item {
  position: relative;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 1.2rem 0;

  &:before {
    content: '';
    position: absolute;
    width: 1.2rem;
    height: 2px;
    top: 50%;
    left: -15%;
    transform: translateY(-50%);
    background-color: $color-black;
    opacity: 0.2;

    @include media('<=phone') {
      width: 12px;
      left: -1%;
    }
  }

  @include media('<=phone') {
    font-size: 16px;
    padding: 12px 20px;
  }
}

.employee__anchor-menu__item__link {
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-lightGreen;
    opacity: 0.8;
  }
}


.employee__column-main {
  padding: 0;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}

.employee__press {
  @extend %section;

  @include media('<=phone') {
    padding: 60px 16px;
  }
}

.employee__press__title {
  @extend %section-title;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 24rem;
    height: 2px;
    top: 50%;
    margin-left: 4rem;
    transform: translateY(-50%);
    background-color: $color-lightGreen;
    opacity: 0.5;
  }
}

.employee__press__news {
  @extend %contents;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.employee__press__news__item {
  padding: 2.4rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);

  &:first-child {
    border-top: 1px solid rgba(51, 51, 51, 0.2);
  }
}

.employee__press__news__item__header {
  display: flex;
  align-items: center;

  @include media('<=phone') {
    display: block;
    padding: 20px 0;
  }
}

.employee__press__news__item__day {
  font-size: 1.4rem;
  opacity: 0.8;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.employee__press__news__item__title {
  margin-left: 4rem;
  font-weight: 700;

  @include media('<=phone') {
    margin-left: 0;
    margin-top: 12px;
    font-size: 14px;
  }
}

.employee__press__news__item__content {
  margin-top: 4rem;
  line-height: 1.5;

  @include media('<=phone') {
    font-size: 12px;
  }
}

.employee__press__news__item__attachedfile {
  margin-top: 4rem;
  color: blue;
  text-decoration: underline;

  @include media('<=phone') {
    font-size: 12px;
  }
}


.employee__maillist {
  @extend %section;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.employee__maillist__title {
  @extend %section-title;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 24rem;
    height: 2px;
    top: 50%;
    margin-left: 4rem;
    transform: translateY(-50%);
    background-color: $color-lightGreen;
    opacity: 0.5;
  }
}

.employee__maillist__branch {
  @extend %contents;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.employee__maillist__branch__title {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 4rem;
}

.employee__maillist__branch__title__name {
  font-size: 2.4rem;
  font-weight: 700;
  color: $color-lightGreen;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.employee__maillist__branch__title__day {
  margin-left: 2.4rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.employee__maillist__branch__list {
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.employee__maillist__branch__list__item {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);

  &:first-child {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
  }
}

.employee__maillist__branch__list__item__name {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: $color-lightGray;

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
  }
}

.employee__maillist__branch__list__item__mailaddress {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px
  }
}

.employee__formats {
  @extend %section;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.employee__formats__title {
  @extend %section-title;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 24rem;
    height: 2px;
    top: 50%;
    margin-left: 4rem;
    transform: translateY(-50%);
    background-color: $color-lightGreen;
    opacity: 0.5;
  }
}

.employee__formats__table {
  @extend %contents;
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.employee__formats__table__row {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);

  &:first-child {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__row {
    margin: 0 -15px 0 -15px;
    width: 100%;
  }
}

.employee__formats__table__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: $color-lightGray;

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__title {
    padding: 2.4rem 15px 2.4rem 15px;
    width: 40%;
  }
}

.employee__formats__table__day {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__day {
    padding: 2.4rem -15px 2.4rem -15px;
    width: 30%;
  }
}

.employee__formats__table__format {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__format {
    padding: 2.4rem -15px 2.4rem -15px;
    width: 30%;
  }
}

.employee__formats__table__format__box {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__formats__table__format__text {
  position: absolute;

  @include media('<=phone') {
    font-size: 14px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__format__text {
    position: static;
  }
}

.employee__formats__table__format__icon {
  position: absolute;
  width: 2rem;
  height: auto;

  right: 4rem;

  @include media('<=phone') {
    display: none;
  }
}

.employee__documents {
  @extend %section;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.employee__documents__title {
  @extend %section-title;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 24rem;
    height: 2px;
    top: 50%;
    margin-left: 4rem;
    transform: translateY(-50%);
    background-color: $color-lightGreen;
    opacity: 0.5;
  }
}

.employee__documents__table {
  @extend %contents;
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.employee__documents__table__row {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);

  &:first-child {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__row {
    margin: 0 -15px 0 -15px;
    width: 100%;
  }
}

.employee__documents__table__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: $color-lightGray;

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__title {
    padding: 2.4rem 15px 2.4rem 15px;
    width: 40%;
  }
}

.employee__documents__table__day {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__day {
    padding: 2.4rem 15px 2.4rem 15px;
    width: 30%;
  }
}


.employee__documents__table__format {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__format {
    padding: 2.4rem -15px 2.4rem -15px;
    width: 30%;
  }
}

.employee__documents__table__format__box {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__documents__table__format__text {
  position: absolute;

  @include media('<=phone') {
    font-size: 14px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__format__text {
    position: static;
  }
}

.employee__documents__table__format__icon {
  position: absolute;
  width: 2rem;
  height: auto;

  right: 4rem;

  @include media('<=phone') {
    display: none;
  }
}


.employee__album {
  @extend %section;
}

.employee__album__title {
  @extend %section-title;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 24rem;
    height: 2px;
    top: 50%;
    margin-left: 4rem;
    transform: translateY(-50%);
    background-color: $color-lightGreen;
    opacity: 0.5;
  }
}

.employee__album__item {
  @extend %contents;

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.employee__album__item__day {
  font-size: 1.5rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 12px;
  }
}

.employee__album__item__title {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.employee__album__item__description {
  margin-top: 2rem;
  line-height: 1.5;

  @include media('<=phone') {
    font-size: 12px;
    margin-top: 20px;
  }
}

.employee__album__item__download {
  @extend %contents;
  margin-top: 4rem;
  border: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.employee__album__item__image {
  width: 100%;
  height: auto;
  padding: 0;
}

.employee__album__item__download__link {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  border-left: 1px solid rgba(51, 51, 51, 0.1);
}

.employee__album__item__download__link__box {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__album__item__download__link__text {
  position: absolute;

  @include media('<=phone') {
    font-size: 14px;
    left: 14px;
  }
}

.employee__formats__table__format__icon {
  position: absolute;
  width: 2rem;
  height: auto;

  right: 4rem;

  @include media('<=phone') {
    display: none;
  }
}

.employee__links {
  @extend %section;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.employee__links__title {
  @extend %section-title;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 24rem;
    height: 2px;
    top: 50%;
    margin-left: 4rem;
    transform: translateY(-50%);
    background-color: $color-lightGreen;
    opacity: 0.5;
  }
}

.employee__links__list {
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.employee__links__item {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);

  &:first-child {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
  }
}

.employee__links__item__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: $color-lightGray;

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
  }
}

.employee__links__item__name {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
  }
}

.employee__links__item__link {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__links__item__link__text {
  position: absolute;

  @include media('<=phone') {
    font-size: 14px;
    padding: 12px;
    text-decoration: underline;
  }
}

.employee__links__item__link__icon {
  position: absolute;
  width: 2rem;
  height: auto;
  right: 4rem;

  @include media('<=phone') {
    display: none;
  }
}

