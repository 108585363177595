.career-recruit__hero {
  display:flex;
  align-items: center;
  position: relative;
  margin-bottom: 6rem;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%,rgba(51, 51, 51, 0.2) 100%), url('../images/career-recruit_main.jpg');
  background-position: left 10% top 27%;
  background-size: 200rem 130rem;

  @include media('<=phone') {
    height: 70vh;
    background-size: 1000px 650px;
    background-position: left 37% top 27%;
  }
}

.career-recruit__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.career-recruit__hero__main {
  @extend %page-title;
}

.career-recruit__hero__sub {
  @extend %page-title-caption;
  margin-top: 1.6rem;
}

.career-recruit__message {
  @extend %section;
  padding: 10rem 35rem;
  text-align: center;

  @include media('<=phone') {
    padding: 60px 16px;
  }
}

.career-recruit__message__main {
  text-align: center;
  font-size: 2.7rem;
  font-weight: 700;

  letter-spacing: 0.4rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 20px;
    margin-top: 30px;
  }
}

.career-recruit__message__main__letter {
  color: $color-lightGreen;
  text-decoration: underline;
}

.career-recruit__message__sub {
  text-align: center;
  margin-top: 4rem;
  font-size: 1.6rem;

  letter-spacing: 0.2rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 14px;
    margin-top: 30px;
  }
}

.career-recruit__flow {
  @extend %section;
  padding: 10rem 16rem;
  margin-top: 6rem;
  text-align: center;
  background-color: $color-lightGray;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.career-recruit__flow__title {
  @extend %section-title;
}

.career-recruit__flow__chart {
  @extend %contents;
  margin-left: 0;
  margin-right: 0;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.career-recruit__flow__chart__item {
  display: flex;
  position: relative;
  background-color: $color-white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  height: 24rem;
  padding: 2.4rem;
  border: 1px solid rgba(51, 51, 51, 0.3);

  @include media('<=phone') {
    padding: 12px;
    height: 80px;
  }
}

.career-recruit__flow__chart__item__number {
  color: $color-lightGreen;
  position: absolute;
  font-size: 2.4rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.career-recruit__flow__chart__item__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media('<=phone') {
    font-size: 15px;
    transform: translateY(-20%);
  }
}

.career-recruit__flow__chart__arrow {
  padding: 0;
  width: 4rem;
  height: 4rem;
  fill: $color-lightGreen;

  @include media('<=phone') {
    width: 40px;
    height: 40px;
    transform: rotateZ(90deg);
  }
}

.career-recruit__flow__document {
  display: flex;
  justify-content: center;
  margin-top: 8rem;

  @include media('<=phone') {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}

.career-recruit__flow__document__button {
  @extend %buttonHoverToGreen;
  width: 48rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;

  background-color: $color-white;
  fill: $color-black;
  color: $color-black;
  border: 1px solid rgba(51, 51, 51, 0.3);

  @include media('<=phone') {
    width: 100%;
    padding: 18px 16px;
  }
}

.career-recruit__flow__document__button__icon {
  position: absolute;
  width: 3.2rem;
  height: auto;

  left: 2rem;

  @include media('<=phone') {
    width: 14px;
    left: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .career-recruit__flow__document__button__icon {
    top: 0.4rem;
  }
}

.career-recruit__flow__document__button__text {
  position: absolute;

  font-size: 1.8rem;
  font-weight: 700;

  left: 50%;
  transform: translateX(-50%);

  @include media('<=phone') {
    font-size: 13px;
  }
}

.career-recruit__flow__application__button {
  width: 48rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 6rem;

  background-color: $color-lightGreen;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.4;
    transform: scale(0.99)
  }

  @include media('<=phone') {
    margin-top: 30px;
    margin-left: 0;
    width: 100%;
    padding: 18px 16px;
  }
}

.career-recruit__flow__application__button__pen-icon {
  position: absolute;
  width: 3.2rem;
  height: auto;
  fill: $color-white;

  left: 2rem;

  @include media('<=phone') {
    width: 14px;
    left: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .career-recruit__flow__application__button__pen-icon {
    top: 0.4rem;
  }
}

.career-recruit__flow__application__button__text {
  position: absolute;

  color: $color-black;
  color: $color-white;
  font-size: 1.8rem;
  font-weight: 700;

  left: 50%;
  transform: translateX(-50%);

  @include media('<=phone') {
    font-size: 13px;
  }
}

.career-recruit__job-description {
  @extend %section;
  padding: 10rem;
  text-align: center;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.career-recruit__job-description__title {
  @extend %section-title;
}

.career-recruit__job-description__subtitle {
  @extend %section-subtitle;
  margin-top: 6rem;
  font-size: 2rem;

  @include media('<=phone') {
    font-size: 10px;
    margin-top: 8px;
  }
}

.career-recruit__job-description__list {
  @extend %contents;
  margin-top: 3.2rem;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.career-recruit__job-description__item {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);

  &:first-child {
    border-top: 1px solid rgba(51, 51, 51, 0.1);
  }
}

.career-recruit__job-description__item__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: $color-lightGray;

  @include media('<=phone') {
    padding: 12px;
    font-size: 12px;
  }
}

.career-recruit__job-description__item__description {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;

  @include media('<=phone') {
    padding: 12px;
    font-size: 12px;
  }
}
