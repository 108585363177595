@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.employee__links, .employee__album, .employee__documents, .employee__formats, .employee__maillist, .employee__press, .contact__compliance, .contact__mailform, .contact__tel-fax, .career-recruit__job-description, .career-recruit__flow, .career-recruit__message, .recruit__job-description, .recruit__flow, .recruit__message, .company__history, .company__qualification, .company__access, .company__overview, .company__message, .business__clients, .business__main__inspect, .business__main__measure, .business__main__plan, .business__main__construction, .home__contact, .home__recruit, .home__company, .home__business {
  width: 100%;
  padding: 10rem 20rem;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .employee__links, .employee__album, .employee__documents, .employee__formats, .employee__maillist, .employee__press, .contact__compliance, .contact__mailform, .contact__tel-fax, .career-recruit__job-description, .career-recruit__flow, .career-recruit__message, .recruit__job-description, .recruit__flow, .recruit__message, .company__history, .company__qualification, .company__access, .company__overview, .company__message, .business__clients, .business__main__inspect, .business__main__measure, .business__main__plan, .business__main__construction, .home__contact, .home__recruit, .home__company, .home__business {
    padding: 40px 16px;
  }
}

.employee__album__item__download, .employee__album__item, .employee__documents__table, .employee__formats__table, .employee__maillist__branch, .employee__press__news, .contact__compliance__list, .contact__mailform__form, .contact__mailform__privacy-policy, .contact__tel-fax__contents, .career-recruit__job-description__list, .career-recruit__flow__chart, .recruit__job-description__list, .recruit__flow__chart, .company__history__list, .company__qualification__list, .company__access__office, .company__overview__list, .business__main__inspect__contents, .business__main__measure__contents, .business__main__plan__contents, .business__main__construction__contents, .home__contact__contents, .home__recruit__contents, .home__business__contents {
  width: 100%;
  margin-top: 7.5rem;
}

.contact__mailform__form__back-button, .contact__mailform__form__reset-button, .career-recruit__flow__document__button, .recruit__flow__document__button, .company__access__office__button, .home__recruit__contents__item__button, .home__company__button {
  transition: 0.3s ease-out;
}
.contact__mailform__form__back-button:hover, .contact__mailform__form__reset-button:hover, .career-recruit__flow__document__button:hover, .recruit__flow__document__button:hover, .company__access__office__button:hover, .home__recruit__contents__item__button:hover, .home__company__button:hover {
  color: #ffffff;
  fill: #ffffff;
  background-color: #8fc31f;
  border: none;
  transform: scale(0.99);
}

.image-margin {
  margin-top: 2.4rem;
}

.section__divider {
  margin: 0 auto;
  height: 1px;
  width: 140rem;
  background-color: #333333;
  opacity: 0.1;
}

.image-divider {
  margin-top: 2.4rem;
}

.business__clients__number, .business__main__inspect__number, .business__main__measure__number, .business__main__plan__number, .business__main__construction__number {
  font-size: 4rem;
  font-size: 700;
  color: #8fc31f;
}
@media (max-width: 768px) {
  .business__clients__number, .business__main__inspect__number, .business__main__measure__number, .business__main__plan__number, .business__main__construction__number {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.employee__links__title, .employee__album__title, .employee__documents__title, .employee__formats__title, .employee__maillist__title, .employee__press__title, .contact__compliance__title__main, .contact__mailform__title__main, .contact__tel-fax__title__main, .career-recruit__job-description__title, .career-recruit__flow__title, .recruit__job-description__title, .recruit__flow__title, .company__history__title__main, .company__qualification__title__main, .company__access__title__main, .company__overview__title__main, .company__message__title__main, .business__clients__text, .business__main__inspect__text, .business__main__measure__text, .business__main__plan__text, .business__main__construction__text, .home__contact__title__main, .home__recruit__title__main, .home__company__title__main, .home__business__title__main {
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
}
@media (max-width: 768px) {
  .employee__links__title, .employee__album__title, .employee__documents__title, .employee__formats__title, .employee__maillist__title, .employee__press__title, .contact__compliance__title__main, .contact__mailform__title__main, .contact__tel-fax__title__main, .career-recruit__job-description__title, .career-recruit__flow__title, .recruit__job-description__title, .recruit__flow__title, .company__history__title__main, .company__qualification__title__main, .company__access__title__main, .company__overview__title__main, .company__message__title__main, .business__clients__text, .business__main__inspect__text, .business__main__measure__text, .business__main__plan__text, .business__main__construction__text, .home__contact__title__main, .home__recruit__title__main, .home__company__title__main, .home__business__title__main {
    font-size: 16px;
  }
}

.contact__compliance__title__sub, .contact__mailform__title__sub, .contact__tel-fax__title__sub, .career-recruit__job-description__subtitle, .recruit__job-description__subtitle, .company__history__title__sub, .company__qualification__title__sub, .company__access__title__sub, .company__overview__title__sub, .company__message__title__sub, .home__contact__title__sub, .home__recruit__title__sub, .home__company__title__sub, .home__business__title__sub {
  margin-top: 1.2rem;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  color: #8fc31f;
}
@media (max-width: 768px) {
  .contact__compliance__title__sub, .contact__mailform__title__sub, .contact__tel-fax__title__sub, .career-recruit__job-description__subtitle, .recruit__job-description__subtitle, .company__history__title__sub, .company__qualification__title__sub, .company__access__title__sub, .company__overview__title__sub, .company__message__title__sub, .home__contact__title__sub, .home__recruit__title__sub, .home__company__title__sub, .home__business__title__sub {
    font-size: 10px;
    margin-top: 8px;
  }
}

.contact__compliance__title__description, .contact__mailform__description, .business__main__inspect__description, .business__main__measure__description, .business__main__plan__description, .business__main__construction__description, .home__contact__title__description {
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 1.8;
}
@media (max-width: 768px) {
  .contact__compliance__title__description, .contact__mailform__description, .business__main__inspect__description, .business__main__measure__description, .business__main__plan__description, .business__main__construction__description, .home__contact__title__description {
    font-size: 14px;
    margin-top: 20px;
  }
}

.employee__hero__main, .contact__hero__main, .career-recruit__hero__main, .recruit__hero__main, .company__hero__main, .business__hero__main {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .employee__hero__main, .contact__hero__main, .career-recruit__hero__main, .recruit__hero__main, .company__hero__main, .business__hero__main {
    font-size: 32px;
  }
}

.employee__hero__sub, .contact__hero__sub, .career-recruit__hero__sub, .recruit__hero__sub, .company__hero__sub, .business__hero__sub {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 1.6rem;
}
@media (max-width: 768px) {
  .employee__hero__sub, .contact__hero__sub, .career-recruit__hero__sub, .recruit__hero__sub, .company__hero__sub, .business__hero__sub {
    font-size: 14px;
    margin-top: 16px;
  }
}

.home__recruit__contents__item__description__item, .home__company__description, .home__business__contents__item__description {
  font-size: 1.5rem;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .home__recruit__contents__item__description__item, .home__company__description, .home__business__contents__item__description {
    font-size: 14px;
  }
}

html {
  background: #ffffff;
  color: #333333;
  box-sizing: border-box;
  overscroll-behavior: none;
  font-size: calc(100vw / 1600 * 10);
  height: 100%;
  left: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  font-size: 1.6rem;
  letter-spacing: 1px;
  left: 0;
  opacity: 1;
  overscroll-behavior: none;
  top: 0;
  width: 100%;
}

/* IEだけに適応 */
_:lang(x)::-ms-backdrop, .selector {
  font-family: "Segoe UI", Meiryo, sans-serif;
}

a {
  color: inherit;
  outline: none;
  pointer-events: auto;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  border-radius: none;
  color: inherit;
  font: inherit;
  outline: none;
  pointer-events: auto;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

input,
textarea {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  pointer-events: auto;
}

.navbar {
  width: 100vw;
  height: 10rem;
  display: flex;
  padding: 0 0 0 3.2rem;
  margin: 0 0;
  justify-content: space-between;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .navbar {
    height: 10vh;
  }
}

.header__image {
  height: 4rem;
}
@media (max-width: 768px) {
  .header__image {
    height: 24px;
  }
}

.header__menu {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
}
@media (max-width: 768px) {
  .header__menu {
    display: none;
  }
}

.header__menu__line {
  width: 2px;
  height: 1.6rem;
  margin-left: 2.6rem;
  background-color: #333333;
  opacity: 0.3;
}
.header__menu__line:first-child {
  margin-left: 0;
}

.header__menu__item {
  margin-left: 2.6rem;
}

.header__menu__item__link {
  color: #333333;
}
.header__menu__item__link:hover {
  color: #8fc31f;
}

.nav-line {
  position: relative;
  overflow: hidden;
}
.nav-line:after {
  display: block;
  content: "";
  height: 2px;
  background-color: #8fc31f;
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateX(-101%);
}
.nav-line:hover::after {
  transition: transform 0.3s cubic-bezier(0.22, 0.94, 0.71, 0.92);
  transform: translateX(0);
}
.nav-line.animate-out::after {
  transition: transform 0.3s cubic-bezier(0.22, 0.94, 0.71, 0.92);
  transform: translateX(100%);
}

.header__employee {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-left: 5.4rem;
  background-color: #8fc31f;
}
.header__employee:hover {
  opacity: 0.8;
}

.header__employee__link:hover {
  text-decoration: none;
}

.header__employee__icon {
  height: 2.4rem;
}

.header__employee__title {
  color: #ffffff;
  font-size: 1.3rem;
  margin-top: 1.6rem;
}

.header__menu__mobile {
  display: none;
  height: 100%;
  padding: 0 16px;
  background-color: #8fc31f;
}
@media (max-width: 768px) {
  .header__menu__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.header__menu__mobile__icon {
  fill: #ffffff;
  width: 32px;
}

.header__menu__mobile__text {
  margin-top: 4px;
  color: white;
  font-size: 11px;
  font-weight: 700;
}

.header__menu__mobile__open {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 101vh;
  padding: 100px 16px;
  z-index: 9999;
}
@media (max-width: 768px) {
  .header__menu__mobile__open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #8fc31f;
  }
}

.header__menu__mobile__open__menu {
  width: 100%;
  height: 100%;
}

.header__menu__mobile__close {
  position: absolute;
  top: 12px;
  right: 17px;
  fill: #ffffff;
  width: 32px;
}

.header__menu__mobile__open__menu__item {
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.header__menu__mobile__open__menu__item:first-child {
  margin-top: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.header__menu__mobile__open__menu__item__link {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.header__menu__mobile__open__menu__item__lock-icon {
  width: 24px;
  height: 24px;
}

.overflow-hidden {
  overflow: hidden;
}

.active {
  color: #8fc31f;
}

.footer__top {
  width: 100%;
  padding: 10rem 20rem;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .footer__top {
    padding: 40px 16px;
  }
}

.footer__logo {
  width: 100%;
}

.footer__logo__image {
  height: 4rem;
}
@media (max-width: 768px) {
  .footer__logo__image {
    height: 24px;
  }
}

.footer__name {
  margin-top: 8rem;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 768px) {
  .footer__name {
    margin-top: 40px;
    font-size: 18px;
    text-align: left;
  }
}

.footer__office {
  margin-top: 8rem;
}
@media (max-width: 768px) {
  .footer__office {
    margin-top: 40px;
  }
}

.footer__office__item {
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .footer__office__item {
    margin-top: 40px;
  }
  .footer__office__item:first-child {
    margin-top: 0;
  }
}

.footer__office__item__name {
  font-size: 1.7rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .footer__office__item__name {
    font-size: 16px;
  }
}

.footer__office__item__border {
  width: 95%;
  height: 1px;
  background-color: #333333;
  opacity: 0.3;
  margin-top: 0.8rem;
}
@media (max-width: 768px) {
  .footer__office__item__border {
    margin-top: 8px;
  }
}

.footer__office__item__post {
  margin-top: 4rem;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .footer__office__item__post {
    margin-top: 16px;
    font-size: 13px;
  }
}

.footer__office__item__address {
  display: flex;
  align-items: baseline;
  letter-spacing: 0.12rem;
}
@media (max-width: 768px) {
  .footer__office__item__address {
    margin-top: 8px;
  }
}

.footer__office__item__address__text {
  font-size: 1.5rem;
  line-height: 2.4rem;
}
@media (max-width: 768px) {
  .footer__office__item__address__text {
    font-size: 14px;
    line-height: 24px;
  }
}

.footer__office__item__address__icon {
  height: 2.1rem;
  margin-left: 1.6rem;
}
@media (max-width: 768px) {
  .footer__office__item__address__icon {
    height: 16px;
    margin-left: 16px;
  }
}

.footer__office__item__tel {
  margin-top: 0.2rem;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .footer__office__item__tel {
    margin-top: 8px;
    font-size: 14px;
  }
}

.footer__office__item__fax {
  margin-top: 0.8rem;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .footer__office__item__fax {
    margin-top: 8px;
    font-size: 14px;
  }
}

.footer__bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 0;
  background-color: #333333;
  color: #ffffff;
}
@media (max-width: 768px) {
  .footer__bottom {
    display: block;
    padding: 20px 16px;
  }
}

.footer__bottom__menu {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .footer__bottom__menu {
    flex-direction: column;
  }
}

.footer__bottom__menu__item {
  margin-left: 3.6rem;
  overflow: hidden;
}
.footer__bottom__menu__item:first-child {
  margin-left: 0;
}
@media (max-width: 768px) {
  .footer__bottom__menu__item {
    font-size: 14px;
    margin-left: 0;
    margin-top: 30px;
  }
}

.footer__bottom__menu__item__text {
  color: #ffffff;
}
.footer__bottom__menu__item__text:hover {
  opacity: 1;
  text-decoration: none;
  color: #8fc31f;
}
@media (max-width: 768px) {
  .footer__bottom__menu__item__text {
    display: inline-block;
    font-size: 14px;
    margin-left: 0;
  }
}

.footer__bottom__border {
  width: 82rem;
  height: 1px;
  margin-top: 2rem;
  background-color: #ffffff;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .footer__bottom__border {
    width: 100%;
    margin-top: 30px;
  }
}

.footer__bottom__border__caption {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.3rem;
  letter-spacing: 0.2;
}
@media (max-width: 768px) {
  .footer__bottom__border__caption {
    width: 100%;
    margin-top: 30px;
    font-size: 12px;
    text-align: left;
  }
}

.footer__bottom__pageup {
  position: absolute;
  bottom: 3rem;
  right: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 6.8rem;
  background-color: #8fc31f;
  border-radius: 50%;
  transition: 0.3s ease-out;
}
.footer__bottom__pageup:hover {
  opacity: 0.9;
  transform: scale(0.99);
}
@media (max-width: 768px) {
  .footer__bottom__pageup {
    bottom: 40px;
    right: 16px;
    width: 60px;
    height: 60px;
  }
}

.footer__bottom__pageup__icon {
  width: 5rem;
}
@media (max-width: 768px) {
  .footer__bottom__pageup__icon {
    width: 40px;
  }
}

.home__hero {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 55rem;
  background-image: url("../images/home_main.jpg");
  background-position: bottom 37% right 50%;
  background-size: cover;
}
@media (max-width: 768px) {
  .home__hero {
    height: 70vh;
  }
}

.home__hero__title {
  position: relative;
  vertical-align: middle;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  padding-left: 20rem;
  letter-spacing: 0.3rem;
  background-color: rgba(0, 0, 0, 0.7);
}
.home__hero__title:after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  border-left: 5.2rem solid rgba(0, 0, 0, 0.7);
  border-bottom: 8.4rem solid transparent;
  z-index: 100;
}
@media (max-width: 768px) {
  .home__hero__title:after {
    border-left: 30px solid rgba(0, 0, 0, 0.7);
    border-bottom: 60px solid transparent;
  }
}
@media (max-width: 768px) {
  .home__hero__title {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
  }
}

.home__hero__text {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .home__hero__text {
    font-size: 18px;
  }
}

.home__message {
  width: 100%;
  padding: 10rem;
  text-align: center;
}
@media (max-width: 768px) {
  .home__message {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.home__message__name {
  font-size: 3.9rem;
  font-weight: 700;
  letter-spacing: 1.2rem;
}
@media (max-width: 768px) {
  .home__message__name {
    font-size: 20px;
  }
}

.home__message__content {
  margin-top: 30px;
}
@media (max-width: 768px) {
  .home__message__content {
    font-size: 20px;
  }
}

.home__message__content__title {
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.22rem;
}

.home__message__content__subtitle {
  margin-top: 4.6rem;
  font-size: 1.8rem;
  line-height: 1.6;
  letter-spacing: 0.16rem;
}

.home__divider {
  margin: 0 auto;
  height: 1px;
  width: 140rem;
  background-color: #333333;
  opacity: 0.3;
}

.home__business {
  padding-bottom: 5rem;
}

.home__business__title {
  text-align: center;
}

.home__business__contents {
  margin: 7.5rem 0 0 0;
}
@media (max-width: 768px) {
  .home__business__contents {
    margin: 30px 0 0 0;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .home__business__contents {
    margin: 7.5rem -15px 0 -15px;
  }
}
.home__business__contents__item {
  padding: 0;
}
.home__business__contents__item:nth-child(2), .home__business__contents__item:nth-child(5) {
  margin-left: 2rem;
}
@media (max-width: 768px) {
  .home__business__contents__item:nth-child(2), .home__business__contents__item:nth-child(5) {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .home__business__contents__item {
    margin-top: 30px;
  }
  .home__business__contents__item:first-child {
    margin-top: 0;
  }
}

@media all and (-ms-high-contrast: none) {
  .home__business__contents__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.home__business__contents__item__top {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease-out;
}
.home__business__contents__item__top:hover {
  opacity: 0.6;
  color: #333333;
  transform: scale(0.99);
}
.home__business__contents__item__top:before {
  content: "";
  display: block;
  padding-top: 51%;
}

.home__business__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 17rem;
  height: 7rem;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .home__business__contents__item__title {
    width: 80px;
    height: 32px;
  }
}

.home__business__contents__item__title__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
}
@media (max-width: 768px) {
  .home__business__contents__item__title__text {
    font-size: 14px;
  }
}

.home__business__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.home__business__contents__item__description {
  margin-bottom: 4.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 768px) {
  .home__business__contents__item__description {
    margin-top: 20px;
  }
}

.home__company {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.home__company__box {
  width: 100%;
  margin: 0;
}

.home__company__imagebox {
  position: relative;
  width: 50%;
  overflow: hidden;
  background-image: url("../images/home_company.jpg");
  background-size: cover;
  background-position: center;
}
.home__company__imagebox:before {
  content: "";
  display: block;
  padding-top: 73%;
}
@media (max-width: 768px) {
  .home__company__imagebox {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.home__company__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.home__company__contents {
  position: relative;
  width: 50%;
  padding: 8rem 10rem;
  background-color: #f6f6f6;
  background-image: url("../images/logo.png");
  background-repeat: no-repeat;
  background-position: 200% -60%;
  background-size: 59rem 60rem;
}
@media (max-width: 768px) {
  .home__company__contents {
    width: 100%;
    padding: 40px 16px;
    text-align: center;
  }
}

.home__company__title {
  margin-left: 2rem;
}
@media (max-width: 768px) {
  .home__company__title {
    margin-left: 0;
  }
}

.home__company__description {
  padding-right: 10rem;
  margin-left: 2rem;
  margin-top: 4rem;
}
@media (max-width: 768px) {
  .home__company__description {
    margin-left: 0;
    padding-right: 0;
    margin-top: 20px;
  }
}

.home__company__button {
  width: 30rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 5.7rem;
  margin-left: 2rem;
  color: #333333;
  background-color: #ffffff;
  fill: #333333;
}
@media (max-width: 768px) {
  .home__company__button {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
    padding: 18px 16px;
  }
}

.home__company__button__text {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .home__company__button__text {
    font-size: 13px;
  }
}

.home__company__button__icon {
  position: absolute;
  width: 2.4rem;
  height: auto;
  right: 2.1rem;
}
@media (max-width: 768px) {
  .home__company__button__icon {
    width: 10px;
    right: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .home__company__button__icon {
    top: 0.4rem;
  }
}
.home__recruit {
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f6f6f6 50%, #f6f6f6 100%);
}
@media (max-width: 768px) {
  .home__recruit {
    padding-bottom: 40px;
  }
}

.home__recruit__title {
  text-align: center;
}

.home__recruit__contents {
  margin: 7.5rem 0 0 0;
}
@media (max-width: 768px) {
  .home__recruit__contents {
    margin: 30px 0 0 0;
  }
}

.home__recruit__contents__item {
  padding: 0;
}
.home__recruit__contents__item:last-child {
  margin-left: 2rem;
}
@media (max-width: 768px) {
  .home__recruit__contents__item:last-child {
    margin-left: 0;
    margin-top: 40px;
  }
}

.home__recruit__contents__item__top1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease-out;
  background-image: linear-gradient(rgba(51, 51, 51, 0.5) 0%, rgba(51, 51, 51, 0.5) 100%), url("../images/home_recruit.jpg");
  background-size: cover;
  background-position: center;
}
.home__recruit__contents__item__top1:before {
  content: "";
  display: block;
  padding-top: 51%;
}
.home__recruit__contents__item__top1:hover {
  transform: scale(0.99);
  opacity: 0.8;
}

.home__recruit__contents__item__top2 {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease-out;
  background-image: linear-gradient(rgba(51, 51, 51, 0.5) 0%, rgba(51, 51, 51, 0.5) 100%), url("../images/home_recruit2.jpg");
  background-size: cover;
  background-position: center;
}
.home__recruit__contents__item__top2:before {
  content: "";
  display: block;
  padding-top: 51%;
}
.home__recruit__contents__item__top2:hover {
  transform: scale(0.99);
  opacity: 0.8;
}

.home__recruit__contents__item__top__shape {
  position: absolute;
  top: 0;
  left: 0;
  border-left: 4.5rem solid #8fc31f;
  border-bottom: 4.5rem solid transparent;
}
@media (max-width: 768px) {
  .home__recruit__contents__item__top__shape {
    border-left: 35px solid #8fc31f;
    border-bottom: 35px solid transparent;
  }
}

.home__recruit__contents__item__top__text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 2.5rem;
}
@media (max-width: 768px) {
  .home__recruit__contents__item__top__text {
    font-size: 18px;
  }
}

.home__recruit__contents__item__title {
  width: 100%;
  margin-top: 5rem;
  font-size: 1.7rem;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #333333;
}
@media (max-width: 768px) {
  .home__recruit__contents__item__title {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.home__recruit__contents__item__description {
  width: 100%;
  height: 14rem;
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .home__recruit__contents__item__description {
    margin-top: 20px;
    height: auto;
  }
}

.home__recruit__contents__item__button {
  width: 30rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  color: #333333;
  background-color: #ffffff;
  fill: #333333;
}
@media (max-width: 768px) {
  .home__recruit__contents__item__button {
    margin-top: 20px;
    width: 100%;
    padding: 18px 16px;
  }
}

.home__recruit__contents__item__button__text {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .home__recruit__contents__item__button__text {
    font-size: 13px;
  }
}

.home__recruit__contents__item__button__icon {
  position: absolute;
  width: 2.4rem;
  height: auto;
  right: 2.1rem;
}
@media (max-width: 768px) {
  .home__recruit__contents__item__button__icon {
    width: 10px;
    right: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .home__recruit__contents__item__button__icon {
    top: 0.4rem;
  }
}
.home__contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15rem 20rem;
}
@media (max-width: 768px) {
  .home__contact {
    padding: 40px 16px;
  }
}

.home__contact__title {
  text-align: center;
}

.home__contact__contents {
  margin: 7.5rem 0 0 0;
}
@media (max-width: 768px) {
  .home__contact__contents {
    margin: 30px 0 0 0;
  }
}

.home__contact__contents__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20.5rem;
  padding: 0 0;
  border: 1px solid rgba(51, 51, 51, 0.2);
  margin-left: 2rem;
}
.home__contact__contents__item:first-child {
  margin-left: 0;
}
@media (max-width: 768px) {
  .home__contact__contents__item {
    margin-left: 0;
    height: auto;
    padding: 24px;
  }
  .home__contact__contents__item:last-child {
    margin-top: 20px;
  }
}

.home__contact__contents__item__tel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.home__contact__contents__item__tel__icon {
  width: 3rem;
}
@media (max-width: 768px) {
  .home__contact__contents__item__tel__icon {
    width: 10px;
    right: 16px;
  }
}

.home__contact__contents__item__tel__text {
  margin-left: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .home__contact__contents__item__tel__text {
    font-size: 20px;
  }
}

.home__contact__contents__item__hour {
  margin-top: 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
}
@media (max-width: 768px) {
  .home__contact__contents__item__hour {
    text-align: center;
    font-size: 13px;
    margin-top: 12px;
    letter-spacing: 0;
  }
}

.home__contact__contents__item__fax {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  .home__contact__contents__item__fax {
    margin-top: 12px;
  }
}

.home__contact__contents__item__fax__title {
  font-size: 1.5rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .home__contact__contents__item__fax__title {
    font-size: 12px;
  }
}

.home__contact__contents__item__fax__text {
  margin-left: 2.2rem;
  font-size: 1.8rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .home__contact__contents__item__fax__text {
    text-align: center;
    font-size: 18px;
  }
}

.home__contact__contents__item__mail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.3s ease-out;
}
.home__contact__contents__item__mail:hover {
  opacity: 0.6;
}

.home__contact__contents__item__mail__icon {
  width: 3rem;
}
@media (max-width: 768px) {
  .home__contact__contents__item__mail__icon {
    width: 16px;
  }
}

.home__contact__contents__item__mail__text {
  margin-left: 3rem;
  width: 23rem;
  transform: translateY(11%);
}
@media (max-width: 768px) {
  .home__contact__contents__item__mail__text {
    width: 200px;
  }
}

.home__contact__contents__item__description {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .home__contact__contents__item__description {
    text-align: center;
    font-size: 13px;
    margin-top: 12px;
    letter-spacing: 0;
  }
}

.business__hero {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%, rgba(51, 51, 51, 0.2) 100%), url("../images/business_main.jpg");
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .business__hero {
    height: 70vh;
  }
}

.business__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.business__menu {
  padding: 2.4rem 20rem;
}
@media (max-width: 768px) {
  .business__menu {
    padding: 16px 16px;
  }
}

.business__menu__item {
  position: relative;
  padding: 0;
  transition: 0.3s;
}
.business__menu__item:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 6rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333333;
  opacity: 0.1;
}
@media (max-width: 768px) {
  .business__menu__item:before {
    height: 24px;
  }
}
.business__menu__item:last-child:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 6rem;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  background-color: #333333;
  opacity: 0.1;
}
.business__menu__item:hover {
  opacity: 0.6;
}

.business__menu__item__link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.business__menu__item__link:hover {
  text-decoration: none;
}

.business__menu__item__number {
  font-size: 1.6rem;
  color: #8fc31f;
}
@media (max-width: 768px) {
  .business__menu__item__number {
    font-size: 14px;
  }
}

.business__menu__item__text {
  color: #333333;
  margin-top: 1.2rem;
  font-size: 1.8rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .business__menu__item__text {
    margin-top: 8px;
  }
}

.business__menu__item__icon {
  width: 2.4rem;
  margin-top: 0.8rem;
}
@media (max-width: 768px) {
  .business__menu__item__icon {
    width: 16px;
    margin-top: 4px;
  }
}

.business__main {
  width: 100%;
}

.business__main__construction {
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f6f6f6 50%, #f6f6f6 100%);
}

.business__main__construction__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;
}
.business__main__construction__title:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
.business__main__construction__title:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 100%;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .business__main__construction__title {
    padding: 0 16px;
  }
}

.business__main__construction__text {
  margin-top: 1.6rem;
}

.business__main__construction__description {
  font-size: 2rem;
}
@media (max-width: 768px) {
  .business__main__construction__description {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .business__main__construction__contents {
    margin: 30px 0 0 0;
  }
}

.business__main__construction__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;
}
.business__main__construction__contents__item:first-child :nth-child(3) {
  margin-left: 0;
}
.business__main__construction__contents__item:before {
  content: "";
  display: block;
  padding-top: 68%;
}
@media (max-width: 768px) {
  .business__main__construction__contents__item {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;
  }
  .business__main__construction__contents__item:first-child {
    margin-top: 0;
  }
}

.business__main__construction__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__construction__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .business__main__construction__contents__item__title {
    width: 40%;
    height: 40px;
  }
}

.business__main__construction__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
}
@media (max-width: 768px) {
  .business__main__construction__contents__item__text {
    font-size: 14px;
  }
}

.business__main__plan {
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f6f6f6 50%, #f6f6f6 100%);
}

.business__main__plan__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;
}
.business__main__plan__title:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
.business__main__plan__title:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 100%;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .business__main__plan__title {
    padding: 0 16px;
  }
}

.business__main__plan__text {
  margin-top: 1.6rem;
}

@media (max-width: 768px) {
  .business__main__plan__contents {
    margin: 30px 0 0 0;
  }
}

.business__main__plan__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;
}
.business__main__plan__contents__item:first-child :nth-child(3) {
  margin-left: 0;
}
.business__main__plan__contents__item:before {
  content: "";
  display: block;
  padding-top: 78%;
}
@media (max-width: 768px) {
  .business__main__plan__contents__item:before {
    padding-top: 68%;
  }
}
@media (max-width: 768px) {
  .business__main__plan__contents__item {
    margin-top: 30px;
    margin-left: 0;
    padding: 0;
  }
  .business__main__plan__contents__item:first-child {
    margin-top: 0;
  }
}

.business__main__plan__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__plan__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .business__main__plan__contents__item__title {
    width: 40%;
    height: 40px;
  }
}

.business__main__plan__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
}
@media (max-width: 768px) {
  .business__main__plan__contents__item__text {
    font-size: 14px;
  }
}

.business__main__measure {
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f6f6f6 50%, #f6f6f6 100%);
}

.business__main__measure__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;
}
.business__main__measure__title:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
.business__main__measure__title:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 100%;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .business__main__measure__title {
    padding: 0 16px;
  }
}

.business__main__measure__text {
  margin-top: 1.6rem;
}

.business__main__measure__description {
  font-size: 2rem;
}
@media (max-width: 768px) {
  .business__main__measure__description {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .business__main__measure__contents {
    margin: 30px 0 0 0;
  }
}

.business__main__measure__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;
}
.business__main__measure__contents__item:first-child :nth-child(3) {
  margin-left: 0;
}
.business__main__measure__contents__item:before {
  content: "";
  display: block;
  padding-top: 68%;
}
@media (max-width: 768px) {
  .business__main__measure__contents__item {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;
  }
  .business__main__measure__contents__item:first-child {
    margin-top: 0;
  }
}

.business__main__measure__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__measure__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .business__main__measure__contents__item__title {
    width: 40%;
    height: 40px;
  }
}

.business__main__measure__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
}
@media (max-width: 768px) {
  .business__main__measure__contents__item__text {
    font-size: 14px;
  }
}

.business__main__inspect {
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #f6f6f6 50%, #f6f6f6 100%);
}

.business__main__inspect__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;
}
.business__main__inspect__title:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
.business__main__inspect__title:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 100%;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .business__main__inspect__title {
    padding: 0 16px;
  }
}

.business__main__inspect__text {
  margin-top: 1.6rem;
}

@media (max-width: 768px) {
  .business__main__inspect__contents {
    margin: 30px 0 0 0;
  }
}

.business__main__inspect__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;
}
.business__main__inspect__contents__item:first-child :nth-child(3) {
  margin-left: 0;
}
.business__main__inspect__contents__item:before {
  content: "";
  display: block;
  padding-top: 75%;
}
@media (max-width: 768px) {
  .business__main__inspect__contents__item:before {
    padding-top: 68%;
  }
}
@media (max-width: 768px) {
  .business__main__inspect__contents__item {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;
  }
  .business__main__inspect__contents__item:first-child {
    margin-top: 0;
  }
}

.business__main__inspect__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__inspect__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .business__main__inspect__contents__item__title {
    width: 40%;
    height: 40px;
  }
}

.business__main__inspect__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
}
@media (max-width: 768px) {
  .business__main__inspect__contents__item__text {
    font-size: 14px;
  }
}

.business__clients {
  text-align: center;
}

.business__clients__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;
}
.business__clients__title:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
.business__clients__title:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 100%;
  transform: translateY(-47%);
  background-color: #8fc31f;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .business__clients__title {
    padding: 0 16px;
  }
}

.business__clients__text {
  margin-top: 1.6rem;
}

.business__clients__list {
  width: 100%;
  margin-top: 4rem;
}
@media (max-width: 768px) {
  .business__clients__list {
    margin: 30px 0 0 0;
  }
}

.business__clients__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  .business__clients__logo {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;
  }
  .business__clients__logo:first-child {
    margin-top: 0;
  }
}

.business__clients__image {
  width: 40%;
  height: auto;
}

.business__clients__caption {
  margin-top: 5rem;
  font-size: 1.4rem;
}
@media (max-width: 768px) {
  .business__clients__caption {
    margin-top: 30px;
    font-size: 14px;
  }
}

.business__clients__others {
  margin-top: 6rem;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .business__clients__others {
    margin-top: 30px;
    font-size: 16px;
  }
}

.company__hero {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60vh;
  margin-bottom: 6rem;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%, rgba(51, 51, 51, 0.2) 100%), url("../images/company_main.jpg");
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .company__hero {
    height: 70vh;
  }
}

.company__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.company__hero__sub {
  margin-top: 1.6rem;
}

.company__message {
  padding: 6rem 35rem;
  text-align: center;
}
@media (max-width: 768px) {
  .company__message {
    padding: 60px 16px;
  }
}

.company__message__description {
  text-align: left;
  margin-top: 6rem;
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.4rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .company__message__description {
    font-size: 18px;
    margin-top: 30px;
  }
}

.company__message__post {
  text-align: right;
  margin-top: 4rem;
  font-size: 1.7rem;
}
@media (max-width: 768px) {
  .company__message__post {
    font-size: 14px;
    margin-top: 20px;
  }
}

.company__message__name {
  text-align: right;
  margin-top: 1.6rem;
  font-size: 2.4rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .company__message__name {
    font-size: 18px;
    margin-top: 14px;
  }
}

.company__overview {
  padding: 10rem 24rem;
  text-align: center;
}
@media (max-width: 768px) {
  .company__overview {
    padding: 40px 16px;
  }
}

.company__overview__list {
  width: 100%;
}
@media (max-width: 768px) {
  .company__overview__list {
    margin: 30px 0 0 0;
  }
}

.company__overview__item {
  padding: 2.4rem;
  margin: 0;
}
.company__overview__item:nth-child(even) {
  background-color: #f6f6f6;
}
.company__overview__item:nth-child(odd) {
  background-color: #eeeeee;
}
@media (max-width: 768px) {
  .company__overview__item {
    padding: 12px;
  }
}

.company__overview__item__title {
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
}
@media (max-width: 768px) {
  .company__overview__item__title {
    font-size: 14px;
  }
}

.company__overview__item__description {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .company__overview__item__description {
    font-size: 14px;
  }
}

.company__access {
  text-align: center;
  background-color: #f6f6f6;
}

.company__access__office {
  width: 100%;
  margin: 5rem 0 0 0;
}
@media (max-width: 768px) {
  .company__access__office {
    margin-top: 30px;
  }
}

.company__access__office__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
  margin-left: 2.4rem;
}
.company__access__office__item:first-child {
  margin-left: 0;
}
@media (max-width: 768px) {
  .company__access__office__item {
    margin-top: 40px;
    margin-left: 0;
  }
  .company__access__office__item:first-child {
    margin-top: 0;
  }
}

.company__access__office__item__name {
  font-size: 2rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .company__access__office__item__name {
    font-size: 16px;
  }
}

.company__access__office__item__map {
  margin-top: 4rem;
  width: 100%;
  height: 35vh;
}
@media (max-width: 768px) {
  .company__access__office__item__map {
    margin-top: 20px;
  }
}

.company__access__office__button {
  width: 30rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 4rem;
  color: #333333;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .company__access__office__button {
    margin-top: 20px;
    width: 100%;
    padding: 18px 16px;
    color: #ffffff;
  }
}

.company__access__office__button__text {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .company__access__office__button__text {
    font-size: 13px;
  }
}

.company__access__office__button__icon {
  position: absolute;
  width: 1.6rem;
  height: auto;
  right: 2rem;
}
@media (max-width: 768px) {
  .company__access__office__button__icon {
    width: 10px;
    right: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .company__access__office__button__icon {
    top: 0.4rem;
  }
}
.company__qualification {
  text-align: center;
}

.company__qualification__list {
  width: 100%;
}
@media (max-width: 768px) {
  .company__qualification__list {
    margin: 30px 0 0 0;
  }
  .company__qualification__list:first-child {
    margin-top: 0;
  }
}

.company__qualification__list__items {
  width: 100%;
}
@media (max-width: 768px) {
  .company__qualification__list__items {
    padding: 0;
    margin-top: 30px;
  }
}

.company__qualification__list__item {
  padding: 1.6rem 2.4rem;
  margin: 0;
}
.company__qualification__list__item:nth-child(even) {
  background-color: #f6f6f6;
}
.company__qualification__list__item:nth-child(odd) {
  background-color: #eeeeee;
}
.company__qualification__list__item:first-child {
  font-weight: 700;
  padding: 1.4rem 2.4rem;
  background-color: #eaf8cb;
}

.company__qualification__list__item__name {
  padding: 0;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .company__qualification__list__item__name {
    font-size: 13px;
  }
}

.company__qualification__list__item__number {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .company__qualification__list__item__number {
    font-size: 13px;
  }
}

.company__history {
  padding: 10rem 24rem;
  text-align: center;
}
@media (max-width: 768px) {
  .company__history {
    padding: 40px 16px;
  }
}

.company__history__list {
  width: 100%;
}
@media (max-width: 768px) {
  .company__history__list {
    margin: 30px 0 0 0;
  }
}

.company__history__item {
  padding: 2.4rem;
  margin: 0;
}
.company__history__item:nth-child(even) {
  background-color: #f6f6f6;
}
.company__history__item:nth-child(odd) {
  background-color: #eeeeee;
}
@media (max-width: 768px) {
  .company__history__item {
    padding: 12px;
  }
}

.company__history__item__title {
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
}
@media (max-width: 768px) {
  .company__history__item__title {
    font-size: 12px;
  }
}

.company__history__item__description {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .company__history__item__description {
    font-size: 14px;
  }
}

.recruit__hero {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 6rem;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%, rgba(51, 51, 51, 0.2) 100%), url("../images/recruit_main.jpg");
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .recruit__hero {
    height: 70vh;
  }
}

.recruit__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.recruit__hero__sub {
  margin-top: 1.6rem;
}

.recruit__message {
  padding: 10rem 35rem;
  text-align: center;
}
@media (max-width: 768px) {
  .recruit__message {
    padding: 60px 16px;
  }
}

.recruit__message__main {
  text-align: center;
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.4rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .recruit__message__main {
    font-size: 20px;
    margin-top: 30px;
  }
}

.recruit__message__main__letter {
  color: #8fc31f;
  text-decoration: underline;
}

.recruit__message__sub {
  text-align: center;
  margin-top: 4rem;
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .recruit__message__sub {
    font-size: 14px;
    margin-top: 30px;
  }
}

.recruit__flow {
  padding: 10rem 16rem;
  margin-top: 6rem;
  text-align: center;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .recruit__flow {
    padding: 40px 16px;
  }
}

.recruit__flow__chart {
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 768px) {
  .recruit__flow__chart {
    padding: 40px 16px;
  }
}

.recruit__flow__chart__item {
  display: flex;
  position: relative;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  height: 24rem;
  padding: 2.4rem;
  border: 1px solid rgba(51, 51, 51, 0.3);
}
@media (max-width: 768px) {
  .recruit__flow__chart__item {
    padding: 12px;
    height: 80px;
  }
}

.recruit__flow__chart__item__number {
  color: #8fc31f;
  position: absolute;
  font-size: 2.4rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .recruit__flow__chart__item__number {
    font-size: 14px;
  }
}

.recruit__flow__chart__item__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}
@media (max-width: 768px) {
  .recruit__flow__chart__item__text {
    font-size: 15px;
    transform: translateY(-20%);
  }
}

.recruit__flow__chart__arrow {
  padding: 0;
  width: 4rem;
  height: 4rem;
  fill: #8fc31f;
}
@media (max-width: 768px) {
  .recruit__flow__chart__arrow {
    width: 40px;
    height: 40px;
    transform: rotateZ(90deg);
  }
}

.recruit__flow__document {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}
@media (max-width: 768px) {
  .recruit__flow__document {
    flex-direction: column;
    align-items: center;
  }
}

.recruit__flow__document__button {
  width: 48rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  fill: #333333;
  color: #333333;
  border: 1px solid rgba(51, 51, 51, 0.3);
}
@media (max-width: 768px) {
  .recruit__flow__document__button {
    width: 100%;
    padding: 18px 16px;
  }
}

.recruit__flow__document__button__icon {
  position: absolute;
  width: 3.2rem;
  height: auto;
  left: 2rem;
}
@media (max-width: 768px) {
  .recruit__flow__document__button__icon {
    width: 14px;
    left: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .recruit__flow__document__button__icon {
    top: 0.4rem;
  }
}
.recruit__flow__document__button__text {
  position: absolute;
  font-size: 1.8rem;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .recruit__flow__document__button__text {
    font-size: 13px;
  }
}

.recruit__flow__application__button {
  width: 48rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 6rem;
  background-color: #8fc31f;
  transition: 0.3s ease-out;
}
.recruit__flow__application__button:hover {
  opacity: 0.4;
  transform: scale(0.99);
}
@media (max-width: 768px) {
  .recruit__flow__application__button {
    margin-top: 30px;
    margin-left: 0;
    width: 100%;
    padding: 18px 16px;
  }
}

.recruit__flow__application__button__pen-icon {
  position: absolute;
  width: 3.2rem;
  height: auto;
  fill: #ffffff;
  left: 2rem;
}
@media (max-width: 768px) {
  .recruit__flow__application__button__pen-icon {
    width: 14px;
    left: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .recruit__flow__application__button__pen-icon {
    top: 0.4rem;
  }
}
.recruit__flow__application__button__text {
  position: absolute;
  color: #333333;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .recruit__flow__application__button__text {
    font-size: 13px;
  }
}

.recruit__job-description {
  padding: 10rem;
  text-align: center;
}
@media (max-width: 768px) {
  .recruit__job-description {
    padding: 40px 16px;
  }
}

.recruit__job-description__subtitle {
  margin-top: 6rem;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .recruit__job-description__subtitle {
    font-size: 10px;
    margin-top: 8px;
  }
}

.recruit__job-description__list {
  margin-top: 3.2rem;
}
@media (max-width: 768px) {
  .recruit__job-description__list {
    margin: 30px 0 0 0;
  }
}

.recruit__job-description__item {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.recruit__job-description__item:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

.recruit__job-description__item__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .recruit__job-description__item__title {
    padding: 12px;
    font-size: 12px;
  }
}

.recruit__job-description__item__description {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .recruit__job-description__item__description {
    padding: 12px;
    font-size: 12px;
  }
}

.career-recruit__hero {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 6rem;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%, rgba(51, 51, 51, 0.2) 100%), url("../images/career-recruit_main.jpg");
  background-position: left 10% top 27%;
  background-size: 200rem 130rem;
}
@media (max-width: 768px) {
  .career-recruit__hero {
    height: 70vh;
    background-size: 1000px 650px;
    background-position: left 37% top 27%;
  }
}

.career-recruit__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.career-recruit__hero__sub {
  margin-top: 1.6rem;
}

.career-recruit__message {
  padding: 10rem 35rem;
  text-align: center;
}
@media (max-width: 768px) {
  .career-recruit__message {
    padding: 60px 16px;
  }
}

.career-recruit__message__main {
  text-align: center;
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.4rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .career-recruit__message__main {
    font-size: 20px;
    margin-top: 30px;
  }
}

.career-recruit__message__main__letter {
  color: #8fc31f;
  text-decoration: underline;
}

.career-recruit__message__sub {
  text-align: center;
  margin-top: 4rem;
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .career-recruit__message__sub {
    font-size: 14px;
    margin-top: 30px;
  }
}

.career-recruit__flow {
  padding: 10rem 16rem;
  margin-top: 6rem;
  text-align: center;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .career-recruit__flow {
    padding: 40px 16px;
  }
}

.career-recruit__flow__chart {
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 768px) {
  .career-recruit__flow__chart {
    padding: 40px 16px;
  }
}

.career-recruit__flow__chart__item {
  display: flex;
  position: relative;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  height: 24rem;
  padding: 2.4rem;
  border: 1px solid rgba(51, 51, 51, 0.3);
}
@media (max-width: 768px) {
  .career-recruit__flow__chart__item {
    padding: 12px;
    height: 80px;
  }
}

.career-recruit__flow__chart__item__number {
  color: #8fc31f;
  position: absolute;
  font-size: 2.4rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .career-recruit__flow__chart__item__number {
    font-size: 14px;
  }
}

.career-recruit__flow__chart__item__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}
@media (max-width: 768px) {
  .career-recruit__flow__chart__item__text {
    font-size: 15px;
    transform: translateY(-20%);
  }
}

.career-recruit__flow__chart__arrow {
  padding: 0;
  width: 4rem;
  height: 4rem;
  fill: #8fc31f;
}
@media (max-width: 768px) {
  .career-recruit__flow__chart__arrow {
    width: 40px;
    height: 40px;
    transform: rotateZ(90deg);
  }
}

.career-recruit__flow__document {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}
@media (max-width: 768px) {
  .career-recruit__flow__document {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}

.career-recruit__flow__document__button {
  width: 48rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  fill: #333333;
  color: #333333;
  border: 1px solid rgba(51, 51, 51, 0.3);
}
@media (max-width: 768px) {
  .career-recruit__flow__document__button {
    width: 100%;
    padding: 18px 16px;
  }
}

.career-recruit__flow__document__button__icon {
  position: absolute;
  width: 3.2rem;
  height: auto;
  left: 2rem;
}
@media (max-width: 768px) {
  .career-recruit__flow__document__button__icon {
    width: 14px;
    left: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .career-recruit__flow__document__button__icon {
    top: 0.4rem;
  }
}
.career-recruit__flow__document__button__text {
  position: absolute;
  font-size: 1.8rem;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .career-recruit__flow__document__button__text {
    font-size: 13px;
  }
}

.career-recruit__flow__application__button {
  width: 48rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 6rem;
  background-color: #8fc31f;
  transition: 0.3s ease-out;
}
.career-recruit__flow__application__button:hover {
  opacity: 0.4;
  transform: scale(0.99);
}
@media (max-width: 768px) {
  .career-recruit__flow__application__button {
    margin-top: 30px;
    margin-left: 0;
    width: 100%;
    padding: 18px 16px;
  }
}

.career-recruit__flow__application__button__pen-icon {
  position: absolute;
  width: 3.2rem;
  height: auto;
  fill: #ffffff;
  left: 2rem;
}
@media (max-width: 768px) {
  .career-recruit__flow__application__button__pen-icon {
    width: 14px;
    left: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .career-recruit__flow__application__button__pen-icon {
    top: 0.4rem;
  }
}
.career-recruit__flow__application__button__text {
  position: absolute;
  color: #333333;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 768px) {
  .career-recruit__flow__application__button__text {
    font-size: 13px;
  }
}

.career-recruit__job-description {
  padding: 10rem;
  text-align: center;
}
@media (max-width: 768px) {
  .career-recruit__job-description {
    padding: 40px 16px;
  }
}

.career-recruit__job-description__subtitle {
  margin-top: 6rem;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .career-recruit__job-description__subtitle {
    font-size: 10px;
    margin-top: 8px;
  }
}

.career-recruit__job-description__list {
  margin-top: 3.2rem;
}
@media (max-width: 768px) {
  .career-recruit__job-description__list {
    margin: 30px 0 0 0;
  }
}

.career-recruit__job-description__item {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.career-recruit__job-description__item:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

.career-recruit__job-description__item__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .career-recruit__job-description__item__title {
    padding: 12px;
    font-size: 12px;
  }
}

.career-recruit__job-description__item__description {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .career-recruit__job-description__item__description {
    padding: 12px;
    font-size: 12px;
  }
}

.contact__hero {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 6rem;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%, rgba(51, 51, 51, 0.2) 100%), url("../images/contact_main.jpg");
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .contact__hero {
    height: 70vh;
  }
}

.contact__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
@media (max-width: 768px) {
  .contact__hero__title {
    width: 100%;
  }
}

.contact__hero__sub {
  margin-top: 1.6rem;
}

.contact__tel-fax {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 10rem;
}
@media (max-width: 768px) {
  .contact__tel-fax {
    padding: 60px 16px;
  }
}

.contact__tel-fax__title {
  text-align: center;
}

@media (max-width: 768px) {
  .contact__tel-fax__contents {
    margin-top: 30px;
  }
}

.contact__tel-fax__contents__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 21rem;
  padding: 0 0;
  border: 1px solid rgba(51, 51, 51, 0.2);
  margin-left: 2rem;
}
.contact__tel-fax__contents__item:first-child {
  margin-left: 0;
}
@media (max-width: 768px) {
  .contact__tel-fax__contents__item {
    height: 100px;
    margin-left: 0;
    margin-top: 30px;
  }
  .contact__tel-fax__contents__itemfirst-child {
    margin-top: 0;
  }
}

.contact__tel-fax__contents__item__tel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact__tel-fax__contents__item__tel__icon {
  width: 3rem;
}
@media (max-width: 768px) {
  .contact__tel-fax__contents__item__tel__icon {
    width: 16px;
  }
}

.contact__tel-fax__contents__item__tel__text {
  margin-left: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .contact__tel-fax__contents__item__tel__text {
    font-size: 18px;
    margin-left: 12px;
  }
}

.contact__tel-fax__contents__item__hour {
  margin-top: 2.5rem;
  font-size: 1.4rem;
}
@media (max-width: 768px) {
  .contact__tel-fax__contents__item__hour {
    margin-top: 12px;
    font-size: 12px;
  }
}

.contact__tel-fax__contents__item__fax {
  display: flex;
  margin-top: 2.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact__tel-fax__contents__item__facsimile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact__tel-fax__contents__item__facsimile__icon {
  width: 3rem;
}
@media (max-width: 768px) {
  .contact__tel-fax__contents__item__facsimile__icon {
    width: 16px;
  }
}

.contact__tel-fax__contents__item__facsimile__text {
  margin-left: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .contact__tel-fax__contents__item__facsimile__text {
    font-size: 18px;
    margin-left: 12px;
  }
}

.contact__mailform {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .contact__mailform {
    padding: 60px 16px;
  }
}

.contact__mailform__title {
  text-align: center;
}

.contact__mailform__description {
  font-size: 1.8rem;
  text-align: center;
}
@media (max-width: 768px) {
  .contact__mailform__description {
    font-size: 12px;
    margin-top: 30px;
    text-align: left;
  }
}

.contact__mailform__privacy-policy {
  align-items: center;
  border: 1px solid rgba(51, 51, 51, 0.2);
  background-color: #f6f6f6;
}

.contact__mailform__privacy-policy__title {
  width: 100%;
  height: 100%;
  padding: 2.4rem;
}
@media (max-width: 768px) {
  .contact__mailform__privacy-policy__title {
    padding: 12px;
  }
}

.contact__mailform__privacy-policy__title__text {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2;
}
@media (max-width: 768px) {
  .contact__mailform__privacy-policy__title__text {
    font-size: 12px;
  }
}

.contact__mailform__privacy-policy__description {
  width: 100%;
  height: 100%;
  padding: 2.4rem;
  border-left: 1px solid rgba(51, 51, 51, 0.2);
}

.contact__mailform__privacy-policy__description__text {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.5rem;
  line-height: 2;
  border: 1px solid rgba(51, 51, 51, 0.1);
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .contact__mailform__privacy-policy__description__text {
    padding: 12px;
    font-size: 12px;
  }
}

.contact__mailform__table {
  width: 100%;
  border: 1px solid rgba(51, 51, 51, 0.1);
}

.contact__mailform__form__row {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.contact__mailform__form__title {
  padding: 3.6rem;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .contact__mailform__form__title {
    padding: 12px;
    font-size: 12px;
  }
}

.contact__mailform__form__tag {
  display: inline-block;
  padding: 0.2rem 0.6rem;
  margin-left: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #ffffff;
  background-color: orange;
}
@media (max-width: 768px) {
  .contact__mailform__form__tag {
    padding: 2px;
    font-size: 11px;
  }
}

.contact__mailform__form__input-box {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 3.6rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .contact__mailform__form__input-box {
    font-size: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .contact__mailform__form__checkbox-box {
    display: flex;
    left: 40rem;
    bottom: 5rem;
  }
}
.contact__mailform__form__checkbox-label {
  position: relative;
  top: 50%;
  left: 4rem;
  transform: translateY(-50%);
  padding: 2.4rem;
  align-items: center;
  cursor: pointer;
}
.contact__mailform__form__checkbox-label input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.contact__mailform__form__checkbox-label input[type=checkbox] + span:before {
  position: absolute;
  display: inline-block;
  content: "";
  top: 50%;
  left: -1.5rem;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border: 2px solid #ccc;
  border-radius: 0.2rem;
}
@media (max-width: 768px) {
  .contact__mailform__form__checkbox-label input[type=checkbox] + span:before {
    width: 12px;
    height: 12px;
    left: -16px;
    font-size: 12px;
  }
}
.contact__mailform__form__checkbox-label input[type=checkbox]:checked + span:before {
  background-image: url("../images/checked_icon.png");
  background-repeat: no-repeat;
  background-position: 0 0.2rem;
  background-size: cover;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .contact__mailform__form__checkbox {
    left: 325px;
  }
}
.contact__mailform__form__input__box {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .contact__mailform__form__input__box {
    padding: 12px;
  }
}

.contact__mailform__form__input {
  width: 100%;
  margin: 0;
  padding: 1.5rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .contact__mailform__form__input {
    font-size: 12px;
    padding: 12px;
    height: 100%;
  }
}

.contact__mailform__form__title__caption {
  margin-top: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .contact__mailform__form__title__caption {
    font-size: 11px;
  }
}

.contact__mailform__form__file-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2;
}

.contact__mailform__file__input {
  width: 100%;
  padding: 2rem 2rem;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .contact__mailform__file__input {
    padding: 12px;
  }
}

.contact__mailform__form__confirm-buttons {
  margin-top: 8rem;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .contact__mailform__form__confirm-buttons {
    margin-top: 30px;
    width: 100%;
  }
}

.contact__mailform__form__confirm-button {
  width: 20rem;
  padding: 2.4rem 2.5rem;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #8fc31f;
  transition: 0.3s ease-out;
}
.contact__mailform__form__confirm-button:hover {
  opacity: 0.8;
  transform: scale(0.99);
}
@media (max-width: 768px) {
  .contact__mailform__form__confirm-button {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
  }
}

.contact__mailform__form__reset-button {
  width: 20rem;
  padding: 2.4rem 2.5rem;
  margin-left: 6rem;
  color: #333333;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #ffffff;
  outline: 1px solid rgba(51, 51, 51, 0.2);
  outline-offset: -1px;
}
@media (max-width: 768px) {
  .contact__mailform__form__reset-button {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
    margin-left: 0;
  }
}

.contact__mailform__form__submit-buttons {
  margin-top: 8rem;
}
@media (max-width: 768px) {
  .contact__mailform__form__submit-buttons {
    margin-top: 30px;
    width: 100%;
  }
}

.contact__mailform__form__back-button {
  width: 20rem;
  padding: 2.4rem 2.5rem;
  color: #333333;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #ffffff;
  outline: 1px solid rgba(51, 51, 51, 0.2);
  outline-offset: -1px;
}
@media (max-width: 768px) {
  .contact__mailform__form__back-button {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
  }
}

.contact__mailform__form__submit-button {
  width: 20rem;
  padding: 2.4rem 2.5rem;
  margin-left: 6rem;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: #8fc31f;
  transition: 0.3s ease-out;
}
.contact__mailform__form__submit-button:hover {
  opacity: 0.8;
  transform: scale(0.99);
}
@media (max-width: 768px) {
  .contact__mailform__form__submit-button {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
    margin-left: 0;
  }
}

.contact__compliance {
  background-color: #f6f6f6;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  border-top: 1px solid rgba(51, 51, 51, 0.2);
}
@media (max-width: 768px) {
  .contact__compliance {
    padding: 60px 16px;
  }
}

.contact__compliance__title {
  text-align: center;
}

.contact__compliance__title__description {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}
.contact__compliance__title__description:last-child {
  margin-top: 6rem;
}
@media (max-width: 768px) {
  .contact__compliance__title__description {
    font-size: 14px;
    margin-top: 30px;
    text-align: left;
  }
}

.contact__compliance__list {
  background-color: #ffffff;
  padding: 3rem 10rem;
}
@media (max-width: 768px) {
  .contact__compliance__list {
    padding: 16px 16px;
  }
}

.contact__compliance__item {
  font-size: 1.8rem;
  line-height: 2;
  margin-top: 1.8rem;
}
@media (max-width: 768px) {
  .contact__compliance__item {
    margin-top: 16px;
    font-size: 14px;
  }
}

.employee__hero {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%, rgba(51, 51, 51, 0.2) 100%), url("../images/employee_main.jpg");
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .employee__hero {
    height: 70vh;
  }
}

.employee__hero__title {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.employee__hero__sub {
  margin-top: 1.6rem;
}

.employee__contents__wrapper {
  width: 100%;
  margin: 0;
}

.employee__column-side {
  background-color: rgba(51, 51, 51, 0.1);
  margin: 0;
}

.employee__anchor-menu {
  padding: 6rem 10rem;
}
@media (max-width: 768px) {
  .employee__anchor-menu {
    padding: 30px 16px;
  }
}

.employee__anchor-menu__title {
  font-size: 2rem;
  font-weight: 700;
  color: #8fc31f;
}
@media (max-width: 768px) {
  .employee__anchor-menu__title {
    font-size: 18px;
  }
}

.employee__anchor-menu__list {
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .employee__anchor-menu__list {
    margin-top: 20px;
  }
}

.employee__anchor-menu__item {
  position: relative;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 1.2rem 0;
}
.employee__anchor-menu__item:before {
  content: "";
  position: absolute;
  width: 1.2rem;
  height: 2px;
  top: 50%;
  left: -15%;
  transform: translateY(-50%);
  background-color: #333333;
  opacity: 0.2;
}
@media (max-width: 768px) {
  .employee__anchor-menu__item:before {
    width: 12px;
    left: -1%;
  }
}
@media (max-width: 768px) {
  .employee__anchor-menu__item {
    font-size: 16px;
    padding: 12px 20px;
  }
}

.employee__anchor-menu__item__link {
  transition: 0.3s;
}
.employee__anchor-menu__item__link:hover {
  text-decoration: none;
  color: #8fc31f;
  opacity: 0.8;
}

.employee__column-main {
  padding: 0;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}

@media (max-width: 768px) {
  .employee__press {
    padding: 60px 16px;
  }
}

.employee__press__title {
  position: relative;
}
.employee__press__title:after {
  content: "";
  position: absolute;
  width: 24rem;
  height: 2px;
  top: 50%;
  margin-left: 4rem;
  transform: translateY(-50%);
  background-color: #8fc31f;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .employee__press__news {
    margin: 30px 0 0 0;
  }
}

.employee__press__news__item {
  padding: 2.4rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}
.employee__press__news__item:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.2);
}

.employee__press__news__item__header {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .employee__press__news__item__header {
    display: block;
    padding: 20px 0;
  }
}

.employee__press__news__item__day {
  font-size: 1.4rem;
  opacity: 0.8;
}
@media (max-width: 768px) {
  .employee__press__news__item__day {
    font-size: 14px;
  }
}

.employee__press__news__item__title {
  margin-left: 4rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .employee__press__news__item__title {
    margin-left: 0;
    margin-top: 12px;
    font-size: 14px;
  }
}

.employee__press__news__item__content {
  margin-top: 4rem;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .employee__press__news__item__content {
    font-size: 12px;
  }
}

.employee__press__news__item__attachedfile {
  margin-top: 4rem;
  color: blue;
  text-decoration: underline;
}
@media (max-width: 768px) {
  .employee__press__news__item__attachedfile {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .employee__maillist {
    padding: 40px 16px;
  }
}

.employee__maillist__title {
  position: relative;
}
.employee__maillist__title:after {
  content: "";
  position: absolute;
  width: 24rem;
  height: 2px;
  top: 50%;
  margin-left: 4rem;
  transform: translateY(-50%);
  background-color: #8fc31f;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .employee__maillist__branch {
    margin: 30px 0 0 0;
  }
}

.employee__maillist__branch__title {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 4rem;
}

.employee__maillist__branch__title__name {
  font-size: 2.4rem;
  font-weight: 700;
  color: #8fc31f;
}
@media (max-width: 768px) {
  .employee__maillist__branch__title__name {
    font-size: 14px;
  }
}

.employee__maillist__branch__title__day {
  margin-left: 2.4rem;
}
@media (max-width: 768px) {
  .employee__maillist__branch__title__day {
    font-size: 14px;
  }
}

.employee__maillist__branch__list {
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__maillist__branch__list {
    margin-top: 20px;
  }
}

.employee__maillist__branch__list__item {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.employee__maillist__branch__list__item:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

.employee__maillist__branch__list__item__name {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .employee__maillist__branch__list__item__name {
    font-size: 14px;
    padding: 12px;
  }
}

.employee__maillist__branch__list__item__mailaddress {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .employee__maillist__branch__list__item__mailaddress {
    font-size: 14px;
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .employee__formats {
    margin: 30px 0 0 0;
  }
}

.employee__formats__title {
  position: relative;
}
.employee__formats__title:after {
  content: "";
  position: absolute;
  width: 24rem;
  height: 2px;
  top: 50%;
  margin-left: 4rem;
  transform: translateY(-50%);
  background-color: #8fc31f;
  opacity: 0.5;
}

.employee__formats__table {
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__formats__table {
    margin-top: 20px;
  }
}

.employee__formats__table__row {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.employee__formats__table__row:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__row {
    margin: 0 -15px 0 -15px;
    width: 100%;
  }
}
.employee__formats__table__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .employee__formats__table__title {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__title {
    padding: 2.4rem 15px 2.4rem 15px;
    width: 40%;
  }
}
.employee__formats__table__day {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__formats__table__day {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__day {
    padding: 2.4rem -15px 2.4rem -15px;
    width: 30%;
  }
}
.employee__formats__table__format {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__formats__table__format {
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__format {
    padding: 2.4rem -15px 2.4rem -15px;
    width: 30%;
  }
}
.employee__formats__table__format__box {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__formats__table__format__text {
  position: absolute;
}
@media (max-width: 768px) {
  .employee__formats__table__format__text {
    font-size: 14px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__formats__table__format__text {
    position: static;
  }
}
.employee__formats__table__format__icon {
  position: absolute;
  width: 2rem;
  height: auto;
  right: 4rem;
}
@media (max-width: 768px) {
  .employee__formats__table__format__icon {
    display: none;
  }
}

@media (max-width: 768px) {
  .employee__documents {
    margin: 30px 0 0 0;
  }
}

.employee__documents__title {
  position: relative;
}
.employee__documents__title:after {
  content: "";
  position: absolute;
  width: 24rem;
  height: 2px;
  top: 50%;
  margin-left: 4rem;
  transform: translateY(-50%);
  background-color: #8fc31f;
  opacity: 0.5;
}

.employee__documents__table {
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__documents__table {
    margin-top: 20px;
  }
}

.employee__documents__table__row {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.employee__documents__table__row:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__row {
    margin: 0 -15px 0 -15px;
    width: 100%;
  }
}
.employee__documents__table__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .employee__documents__table__title {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__title {
    padding: 2.4rem 15px 2.4rem 15px;
    width: 40%;
  }
}
.employee__documents__table__day {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__documents__table__day {
    font-size: 14px;
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__day {
    padding: 2.4rem 15px 2.4rem 15px;
    width: 30%;
  }
}
.employee__documents__table__format {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__documents__table__format {
    padding: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__format {
    padding: 2.4rem -15px 2.4rem -15px;
    width: 30%;
  }
}
.employee__documents__table__format__box {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__documents__table__format__text {
  position: absolute;
}
@media (max-width: 768px) {
  .employee__documents__table__format__text {
    font-size: 14px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .employee__documents__table__format__text {
    position: static;
  }
}
.employee__documents__table__format__icon {
  position: absolute;
  width: 2rem;
  height: auto;
  right: 4rem;
}
@media (max-width: 768px) {
  .employee__documents__table__format__icon {
    display: none;
  }
}

.employee__album__title {
  position: relative;
}
.employee__album__title:after {
  content: "";
  position: absolute;
  width: 24rem;
  height: 2px;
  top: 50%;
  margin-left: 4rem;
  transform: translateY(-50%);
  background-color: #8fc31f;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .employee__album__item {
    margin-top: 20px;
  }
}

.employee__album__item__day {
  font-size: 1.5rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .employee__album__item__day {
    font-size: 12px;
  }
}

.employee__album__item__title {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
}
@media (max-width: 768px) {
  .employee__album__item__title {
    font-size: 14px;
  }
}

.employee__album__item__description {
  margin-top: 2rem;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .employee__album__item__description {
    font-size: 12px;
    margin-top: 20px;
  }
}

.employee__album__item__download {
  margin-top: 4rem;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__album__item__download {
    margin: 30px 0 0 0;
  }
}

.employee__album__item__image {
  width: 100%;
  height: auto;
  padding: 0;
}

.employee__album__item__download__link {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}

.employee__album__item__download__link__box {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__album__item__download__link__text {
  position: absolute;
}
@media (max-width: 768px) {
  .employee__album__item__download__link__text {
    font-size: 14px;
    left: 14px;
  }
}

.employee__formats__table__format__icon {
  position: absolute;
  width: 2rem;
  height: auto;
  right: 4rem;
}
@media (max-width: 768px) {
  .employee__formats__table__format__icon {
    display: none;
  }
}

@media (max-width: 768px) {
  .employee__links {
    margin: 30px 0 0 0;
  }
}

.employee__links__title {
  position: relative;
}
.employee__links__title:after {
  content: "";
  position: absolute;
  width: 24rem;
  height: 2px;
  top: 50%;
  margin-left: 4rem;
  transform: translateY(-50%);
  background-color: #8fc31f;
  opacity: 0.5;
}

.employee__links__list {
  margin-top: 4rem;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  border-left: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 768px) {
  .employee__links__list {
    margin-top: 20px;
  }
}

.employee__links__item {
  margin: 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.employee__links__item:first-child {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

.employee__links__item__title {
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  background-color: #f6f6f6;
}
@media (max-width: 768px) {
  .employee__links__item__title {
    font-size: 14px;
    padding: 12px;
  }
}

.employee__links__item__name {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 768px) {
  .employee__links__item__name {
    font-size: 14px;
    padding: 12px;
  }
}

.employee__links__item__link {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.employee__links__item__link__text {
  position: absolute;
}
@media (max-width: 768px) {
  .employee__links__item__link__text {
    font-size: 14px;
    padding: 12px;
    text-decoration: underline;
  }
}

.employee__links__item__link__icon {
  position: absolute;
  width: 2rem;
  height: auto;
  right: 4rem;
}
@media (max-width: 768px) {
  .employee__links__item__link__icon {
    display: none;
  }
}