.contact__hero {
  display:flex;
  align-items: center;
  position: relative;
  margin-bottom: 6rem;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%,rgba(51, 51, 51, 0.2) 100%), url('../images/contact_main.jpg');
  background-position: center;
  background-size: cover;

  @include media('<=phone') {
    height: 70vh;
  }
}

.contact__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  @include media('<=phone') {
    width: 100%;
  }

}

.contact__hero__main {
  @extend %page-title;
}

.contact__hero__sub {
  @extend %page-title-caption;
  margin-top: 1.6rem;
}

.contact__tel-fax {
  @extend %section;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 10rem;

  @include media('<=phone') {
    padding: 60px 16px;
  }
}

.contact__tel-fax__title {
  text-align: center;
}

.contact__tel-fax__title__main {
  @extend %section-title;
}

.contact__tel-fax__title__sub {
  @extend %section-subtitle;
}

.contact__tel-fax__contents {
  @extend %contents;

  @include media('<=phone') {
    margin-top: 30px;
  }
}

.contact__tel-fax__contents__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 21rem;

  padding: 0 0;

  border: 1px solid rgba(51,51,51,0.2);

  margin-left: 2rem;

  &:first-child {
    margin-left: 0;
  }

  @include media('<=phone') {
    height: 100px;
    margin-left: 0;
    margin-top: 30px;

    &first-child {
      margin-top: 0;
    }
  }
}

.contact__tel-fax__contents__item__tel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact__tel-fax__contents__item__tel__icon {
  width: 3rem;

  @include media('<=phone') {
    width: 16px;
  }
}

.contact__tel-fax__contents__item__tel__text {
  margin-left: 3rem;
  font-size: 2.5rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 18px;
    margin-left: 12px;
  }
}

.contact__tel-fax__contents__item__hour {
  margin-top: 2.5rem;
  font-size: 1.4rem;

  @include media('<=phone') {
    margin-top: 12px;
    font-size: 12px;
  }
}

.contact__tel-fax__contents__item__fax {
  display: flex;
  margin-top: 2.5rem;
  justify-content: center;
  align-items: center;
  width: 100%
}

.contact__tel-fax__contents__item__facsimile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact__tel-fax__contents__item__facsimile__icon {
  width: 3rem;

  @include media('<=phone') {
    width: 16px;
  }
}

.contact__tel-fax__contents__item__facsimile__text {
  margin-left: 3rem;
  font-size: 2.5rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 18px;
    margin-left: 12px;
  }
}

.contact__mailform {
  @extend %section;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media('<=phone') {
    padding: 60px 16px;
  }
}

.contact__mailform__title {
  text-align: center;
}

.contact__mailform__title__main {
  @extend %section-title;
}

.contact__mailform__title__sub {
  @extend %section-subtitle;
}

.contact__mailform__description {
  @extend %section-title-caption;
  font-size: 1.8rem;
  text-align: center;

  @include media('<=phone') {
    font-size: 12px;
    margin-top: 30px;
    text-align: left;
  }
}

.contact__mailform__privacy-policy {
  @extend %contents;
  align-items: center;
  border: 1px solid rgba(51, 51, 51, 0.2);
  background-color: $color-lightGray;
}

.contact__mailform__privacy-policy__title {
  width: 100%;
  height: 100%;
  padding: 2.4rem;

  @include media('<=phone') {
    padding: 12px;
  }
}

.contact__mailform__privacy-policy__title__text {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2;

  @include media('<=phone') {
    font-size: 12px;
  }
}

.contact__mailform__privacy-policy__description {
  width: 100%;
  height: 100%;
  padding: 2.4rem;
  border-left: 1px solid rgba(51, 51, 51, 0.2);
}

.contact__mailform__privacy-policy__description__text {
  margin: 0;
  padding: 2.4rem;
  text-align: left;
  font-size: 1.5rem;
  line-height: 2;

  border: 1px solid rgba(51, 51, 51, 0.1);
  background-color: $color-white;

  @include media('<=phone') {
    padding: 12px;
    font-size: 12px;
  }
}

.contact__mailform__form {
  @extend %contents;
}

.contact__mailform__table {
  width: 100%;
  border: 1px solid rgba(51, 51, 51, 0.1);
}

.contact__mailform__form__row {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.contact__mailform__form__title {
  padding: 3.6rem;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;

  border-right: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    padding: 12px;
    font-size: 12px;
  }
}

.contact__mailform__form__tag {
  display: inline-block;
  padding: 0.2rem 0.6rem;
  margin-left: 2rem;

  font-size: 1.2rem;
  font-weight: 700;
  color: $color-white;

  background-color: orange;

  @include media('<=phone') {
    padding: 2px;
    font-size: 11px;
  }
}

.contact__mailform__form__input-box {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 3.6rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2;

  @include media('<=phone') {
    font-size: 12px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .contact__mailform__form__checkbox-box {
    display: flex;
    left: 40rem;
    bottom: 5rem;
  }
}

.contact__mailform__form__checkbox-label {
  position: relative;
  top: 50%;
  left: 4rem;
  transform: translateY(-50%);
  padding: 2.4rem;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }

  input[type='checkbox'] + span:before {
    position: absolute;
    display: inline-block;
    content: '';
    top: 50%;
    left: -1.5rem;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    border: 2px solid #ccc;
    border-radius: 0.2rem;

    @include media('<=phone') {
      width: 12px;
      height: 12px;
      left: -16px;
      font-size: 12px;
    }
  }

  input[type='checkbox']:checked + span:before {
    background-image: url('../images/checked_icon.png');
    background-repeat: no-repeat;
    background-position: 0 0.2rem;
    background-size: cover;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .contact__mailform__form__checkbox {
    left: 325px;
  }
}

.contact__mailform__form__input__box {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media('<=phone') {
    padding: 12px;
  }
}

.contact__mailform__form__input {
  width: 100%;
  margin: 0;
  padding: 1.5rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2;

  border: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    font-size: 12px;
    padding: 12px;
    height: 100%;
  }
}

.contact__mailform__form__title__caption {
  margin-top: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;

  @include media('<=phone') {
    font-size: 11px;
  }
}

.contact__mailform__form__file-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2;
}

.contact__mailform__file__input {
  width: 100%;
  padding: 2rem 2rem;
  border: 1px solid rgba(51, 51, 51, 0.1);

  @include media('<=phone') {
    padding: 12px;
   }
}

.contact__mailform__form__confirm-buttons {
  margin-top: 8rem;

  transition: 0.3s;

  @include media('<=phone') {
   margin-top: 30px;
   width: 100%;
  }
}

.contact__mailform__form__confirm-button {
  width: 20rem;
  padding: 2.4rem 2.5rem;

  color: $color-white;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: $color-lightGreen;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.8;
    transform: scale(0.99);
  }

  @include media('<=phone') {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
   }
}

.contact__mailform__form__reset-button {
  @extend %buttonHoverToGreen;
  width: 20rem;
  padding: 2.4rem 2.5rem;
  margin-left: 6rem;

  color: $color-black;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: $color-white;
  outline: 1px solid rgba(51, 51, 51, 0.2);
  outline-offset: -1px;

  @include media('<=phone') {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
    margin-left: 0;
   }
}

.contact__mailform__form__submit-buttons {
  margin-top: 8rem;

  @include media('<=phone') {
    margin-top: 30px;
    width: 100%;
   }
}

.contact__mailform__form__back-button {
  @extend %buttonHoverToGreen;
  width: 20rem;
  padding: 2.4rem 2.5rem;

  color: $color-black;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: $color-white;
  outline: 1px solid rgba(51, 51, 51, 0.2);
  outline-offset: -1px;

  @include media('<=phone') {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
   }
}

.contact__mailform__form__submit-button {
  width: 20rem;
  padding: 2.4rem 2.5rem;
  margin-left: 6rem;

  color: $color-white;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: $color-lightGreen;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.8;
    transform: scale(0.99);
  }

  @include media('<=phone') {
    margin-top: 20px;
    width: 100%;
    font-size: 13px;
    padding: 18px 16px;
    margin-left: 0;
   }
}

.contact__compliance {
  @extend %section;
  background-color: $color-lightGray;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  border-top: 1px solid rgba(51, 51, 51, 0.2);

  @include media('<=phone') {
    padding: 60px 16px;
  }
}

.contact__compliance__title {
  text-align: center;
}

.contact__compliance__title__main {
  @extend %section-title;
}

.contact__compliance__title__sub {
  @extend %section-subtitle;
}

.contact__compliance__title__description {
  @extend %section-title-caption;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;

  &:last-child {
    margin-top: 6rem;
  }

  @include media('<=phone') {
    font-size: 14px;
    margin-top: 30px;
    text-align: left;
  }
}

.contact__compliance__list {
  @extend %contents;
  background-color: $color-white;
  padding: 3rem 10rem;

  @include media('<=phone') {
    padding: 16px 16px;
  }
}

.contact__compliance__item {
  font-size: 1.8rem;
  line-height: 2;
  margin-top: 1.8rem;

  @include media('<=phone') {
    margin-top: 16px;
    font-size: 14px;
  }
}
