%section {
  width: 100%;
  padding: 10rem 20rem;
  margin: 0 auto;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

%contents {
  width: 100%;
  margin-top: 7.5rem;
}

%buttonHoverToGreen {
  transition: 0.3s ease-out;
  &:hover {
    color: $color-white;
    fill: $color-white;
    background-color: $color-lightGreen;
    border: none;
    transform: scale(0.99)
  }
}

.image-margin {
  margin-top: 2.4rem;
}

.section__divider {
  margin: 0 auto;
  height: 1px;
  width: 140rem;

  background-color: $color-black;
  opacity: 0.1;
}

.image-divider {
  margin-top: 2.4rem;
}