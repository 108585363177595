html {
  background: $color-white;
  color: $color-black;
  box-sizing: border-box;
  overscroll-behavior: none;
  font-size: calc(100vw / 1600 * 10);
  height: 100%;
  left: 0;
}


body {
  font-family: -apple-system, 
    BlinkMacSystemFont, Roboto, 
    "Segoe UI", "Helvetica Neue", 
    HelveticaNeue, 
    YuGothic, 
    "Yu Gothic Medium", 
    "Yu Gothic", 
    Verdana, 
    Meiryo, 
    sans-serif;
  
  font-size: 1.6rem;
  letter-spacing: 1px;
  left: 0;
  opacity: 1;
  overscroll-behavior: none;
  top: 0;
  width: 100%;
}

/* IEだけに適応 */
_:lang(x)::-ms-backdrop, .selector {
	font-family: "Segoe UI", Meiryo, sans-serif;
}

a {
  color: inherit;
  outline: none;
  pointer-events: auto;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  border-radius: none;
  color: inherit;
  font: inherit;
  outline: none;
  pointer-events: auto;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

input,
textarea {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  pointer-events: auto;
}