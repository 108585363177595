%section-number {
  font-size: 4rem;
  font-size: 700;
  color: $color-lightGreen;

  @include media('<=phone') {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

%section-title {
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.2rem;

  @include media('<=phone') {
    font-size: 16px;
  }
}

%section-subtitle {
  margin-top: 1.2rem;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  color: $color-lightGreen;

  @include media('<=phone') {
    font-size: 10px;
    margin-top: 8px;
  }
}

%section-title-decoration {
  position: relative;
  display: inline-block;
  padding: 0 2.4rem;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: $color-lightGreen;
    opacity: 0.6;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.6;
  }
}

%section-title-caption {
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 1.8;

  @include media('<=phone') {
    font-size: 14px;
    margin-top: 20px;
  }
}

%page-title {
  color: $color-white;
  font-size: 4rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 32px;
  }
}

%page-title-caption {
  color: $color-white;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 1.6rem;

  @include media('<=phone') {
    font-size: 14px;
    margin-top: 16px;
  }
}