.home__hero {
  display:flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 55rem;

  background-image: url('../images/home_main.jpg');
  background-position: bottom 37% right 50%;
  background-size: cover;

  @include media('<=phone') {
    height: 70vh;
  }
}

.home__hero__title {
  position: relative;
  vertical-align: middle;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  padding-left: 20rem;
  letter-spacing: 0.3rem;
  background-color: rgba(0, 0, 0, 0.7);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    border-left: 5.2rem solid rgba(0, 0, 0, 0.7);
    border-bottom: 8.4rem solid transparent;
    z-index: 100;

    @include media('<=phone') {
      border-left: 30px solid rgba(0, 0, 0, 0.7);
      border-bottom: 60px solid transparent;
    }
  }

  @include media('<=phone') {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
  }
}

.home__hero__text {
  color: $color-white;
  font-size: 4rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 18px;
  }
}

.home__message {
  width: 100%;
  padding: 10rem;
  text-align: center;

  @include media('<=phone') {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.home__message__name {
  font-size: 3.9rem;
  font-weight: 700;
  letter-spacing: 1.2rem;

  @include media('<=phone') {
    font-size: 20px;
  }
}

.home__message__content {
  margin-top:  30px;

  @include media('<=phone') {
    font-size: 20px;
  }
}

.home__message__content__title {
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.22rem;
}

.home__message__content__subtitle {
  margin-top:  4.6rem;
  font-size: 1.8rem;
  line-height: 1.6;
  letter-spacing: 0.16rem;
}

.home__divider {
  margin: 0 auto;
  height: 1px;
  width: 140rem;

  background-color: $color-black;
  opacity: 0.3;
}

.home__business {
  @extend %section;
  padding-bottom: 5rem;
}

.home__business__title {
  text-align: center;
}

.home__business__title__main {
  @extend %section-title;
}

.home__business__title__sub {
  @extend %section-subtitle;
}

.home__business__contents {
  @extend %contents;
  margin: 7.5rem 0 0 0;


  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .home__business__contents {
    margin: 7.5rem -15px 0 -15px;
  }
}

.home__business__contents__item {
  padding: 0;
  
  &:nth-child(2), &:nth-child(5){
    margin-left: 2rem;

    @include media('<=phone') {
      margin-left: 0;
    }
  }

  @include media('<=phone') {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .home__business__contents__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.home__business__contents__item__top {
  position: relative;
  width: 100%;
  overflow: hidden;

  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.6;
    color: $color-black;
    transform: scale(0.99);
  }

  &:before {
    content: "";
    display: block;
    padding-top: 51%;
  }
}

.home__business__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 17rem;
  height: 7rem;
  top: 0;
  left: 0;
  background-color: $color-white;

  @include media('<=phone') {
    width: 80px;
    height: 32px;
  }
}

.home__business__contents__item__title__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}
.home__business__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.home__business__contents__item__description {
  @extend %item-description;
  margin-bottom: 4.5rem;
  margin-top: 2.5rem;

  @include media('<=phone') {
    margin-top: 20px;
  }
}

.home__company {
  @extend %section;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.home__company__box {
    width: 100%;
    margin: 0;
}

.home__company__imagebox {
  position: relative;
  width: 50%;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 73%;
  }

  background-image: url('../images/home_company.jpg');
  background-size: cover;
  background-position: center;

  @include media('<=phone') {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.home__company__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.home__company__contents {
  position: relative;
  width: 50%;
  padding: 8rem 10rem;
  background-color: $color-lightGray;
  background-image: url('../images/logo.png');
  background-repeat: no-repeat;
  background-position: 200% -60%;
  background-size: 59rem 60rem;

  @include media('<=phone') {
    width: 100%;
    padding: 40px 16px;
    text-align:center;
  }
}

.home__company__title {
  margin-left: 2rem;
  @include media('<=phone') {
    margin-left: 0;
  }
}

.home__company__title__main {
  @extend %section-title;
}

.home__company__title__sub {
  @extend %section-subtitle;
}

.home__company__description {
  @extend %item-description;
  padding-right: 10rem;
  margin-left: 2rem;
  margin-top: 4rem;

  @include media('<=phone') {
    margin-left: 0;
    padding-right: 0;
    margin-top: 20px;
  }
}

.home__company__button {
  @extend %buttonHoverToGreen;

  width: 30rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 5.7rem;
  margin-left: 2rem;

  color: $color-black;
  background-color: $color-white;
  fill: $color-black;

  @include media('<=phone') {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
    padding: 18px 16px;
  }
}

.home__company__button__text {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;

  left: 50%;
  transform: translateX(-50%);

  @include media('<=phone') {
    font-size: 13px;
  }
}

.home__company__button__icon {
  position: absolute;
  width: 2.4rem;
  height: auto;

  right: 2.1rem;

  @include media('<=phone') {
    width: 10px;
    right: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .home__company__button__icon {
    top: 0.4rem;
  }
}

.home__recruit {
  @extend %section;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(to bottom, #fff 0%, #fff 50%, $color-lightGray 50%,  $color-lightGray 100%);

  @include media('<=phone') {
    padding-bottom: 40px;
  }
}

.home__recruit__title {
  text-align: center;
}

.home__recruit__title__main {
  @extend %section-title;
}

.home__recruit__title__sub {
  @extend %section-subtitle;
}

.home__recruit__contents {
  @extend %contents;
  margin: 7.5rem 0 0 0;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.home__recruit__contents__item {
  padding: 0;

  &:last-child {
    margin-left: 2rem;

    @include media('<=phone') {
      margin-left: 0;
      margin-top: 40px;
    }
  }
}

.home__recruit__contents__item__top1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease-out;


  &:before {
    content: "";
    display: block;
    padding-top: 51%;
  }

  background-image: linear-gradient(rgba(51,51,51,0.5 ) 0%,rgba(51,51,51,0.5) 100%), url('../images/home_recruit.jpg');

  background-size: cover;
  background-position: center;

  &:hover {
    transform: scale(0.99);
    opacity: 0.8;
  }
}

.home__recruit__contents__item__top2 {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease-out;

  &:before {
    content: "";
    display: block;
    padding-top: 51%;
  }
    background-image: linear-gradient(rgba(51,51,51,0.5 ) 0%,rgba(51,51,51,0.5) 100%), url('../images/home_recruit2.jpg');

  background-size: cover;
  background-position: center;

  &:hover {
    transform: scale(0.99);
    opacity: 0.8;
  }
}

.home__recruit__contents__item__top__shape {
  position: absolute;
  top: 0;
  left: 0;
  border-left: 4.5rem solid #8fc31f;
  border-bottom: 4.5rem solid transparent;

  @include media('<=phone') {
    border-left: 35px solid #8fc31f;
    border-bottom: 35px solid transparent;
  }
}

.home__recruit__contents__item__top__text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  color: $color-white;
  font-size: 2.5rem;

  @include media('<=phone') {
    font-size: 18px;
  }
}

.home__recruit__contents__item__title {
  width: 100%;
  margin-top: 5rem;

  font-size: 1.7rem;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: $color-black;

  @include media('<=phone') {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.home__recruit__contents__item__description {
  width: 100%;
  height: 14rem;
  margin-top: 2rem;

  @include media('<=phone') {
    margin-top: 20px;
    height: auto;
  }
}

.home__recruit__contents__item__description__item {
  @extend %item-description;
}

.home__recruit__contents__item__button {
  @extend %buttonHoverToGreen;
  width: 30rem;
  height: 7.2rem;
  display: flex;
  position: relative;
  align-items: center;

  color: $color-black;
  background-color: $color-white;
  fill: $color-black;

  @include media('<=phone') {
    margin-top: 20px;
    width: 100%;
    padding: 18px 16px;
  }
}

.home__recruit__contents__item__button__text {
  position: absolute;

  font-size: 1.5rem;
  font-weight: 700;

  left: 50%;
  transform: translateX(-50%);

  @include media('<=phone') {
    font-size: 13px;
  }
}

.home__recruit__contents__item__button__icon {
  position: absolute;
  width: 2.4rem;
  height: auto;

  right: 2.1rem;

  @include media('<=phone') {
    width: 10px;
    right: 16px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .home__recruit__contents__item__button__icon {
    top: 0.4rem;
  }
}

.home__contact {
  @extend %section;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15rem 20rem;

  @include media('<=phone') {
    padding: 40px 16px;
  }
}

.home__contact__title {
  text-align: center;
}

.home__contact__title__main {
  @extend %section-title;
}

.home__contact__title__sub {
  @extend %section-subtitle;
}

.home__contact__title__description {
  @extend %section-title-caption;
}

.home__contact__contents {
  @extend %contents;
  margin: 7.5rem 0 0 0;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.home__contact__contents__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20.5rem;

  padding: 0 0;

  border: 1px solid rgba(51,51,51,0.2);

  margin-left: 2rem;

  &:first-child {
    margin-left: 0;
  }

  @include media('<=phone') {
    margin-left: 0;
    height: auto;
    padding: 24px;

    &:last-child {  
      margin-top: 20px;
    }
  }
}

.home__contact__contents__item__tel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.home__contact__contents__item__tel__icon {
  width: 3rem;

  @include media('<=phone') {
    width: 10px;
    right: 16px;
  }
}

.home__contact__contents__item__tel__text {
  margin-left: 3rem;
  font-size: 2.5rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 20px;
  }
}

.home__contact__contents__item__hour {
  margin-top: 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;

  @include media('<=phone') {
    text-align: center;
    font-size: 13px;
    margin-top: 12px;
    letter-spacing: 0;
  }
}

.home__contact__contents__item__fax {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include media('<=phone') {
    margin-top: 12px;
  }
}

.home__contact__contents__item__fax__title {
  font-size: 1.5rem;
  font-weight: 700;

  @include media('<=phone') {
    font-size: 12px;
  }
}

.home__contact__contents__item__fax__text {
  margin-left: 2.2rem;
  font-size: 1.8rem;
  font-weight: 700;

  @include media('<=phone') {
    text-align: center;
    font-size: 18px;
  }
}

.home__contact__contents__item__mail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.6
  }
}

.home__contact__contents__item__mail__icon {
  width: 3rem;

  @include media('<=phone') {
    width: 16px;
  }
}

.home__contact__contents__item__mail__text {
  margin-left: 3rem;
  width: 23rem;
  transform: translateY(11%);

  @include media('<=phone') {
    width: 200px;
  }
}

.home__contact__contents__item__description {
  margin-top: 2rem;   
  text-align: center;
  font-size: 1.4rem;
  line-height: 2;

  @include media('<=phone') {
    text-align: center;
    font-size: 13px;
    margin-top: 12px;
    letter-spacing: 0;
  }
}

