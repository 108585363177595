.navbar {
  width: 100vw;
  height: 10rem;
  display: flex;
  padding: 0 0 0 3.2rem; 
  margin: 0 0;
  justify-content: space-between;

  background-color: $color-white;

  @include media('<=phone') {
    height: 10vh;
  }
}

.header__image {
  height: 4rem;

  @include media('<=phone') {
    height: 24px;
  }
}

.header__menu {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 700;

  @include media('<=phone') {
    display: none;
  }
}

.header__menu__line {
  width: 2px;
  height: 1.6rem;
  margin-left: 2.6rem; 
  background-color: $color-black;
  opacity: 0.3;

  &:first-child {
    margin-left: 0;
  }
}

.header__menu__item {
  margin-left: 2.6rem;
}

.header__menu__item__link{
  color: $color-black;

  &:hover {
    color: $color-lightGreen;
  }
}

.nav-line {
  position: relative;
  overflow: hidden;
  
  &:after {
    display: block;
    content: '';
    height: 2px;
    background-color: $color-lightGreen;
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateX(-101%);
  }

  &:hover::after {
    transition: transform 0.3s cubic-bezier(.22,.94,.71,.92);
    transform: translateX(0);
  }

  &.animate-out::after {
    transition: transform 0.3s cubic-bezier(.22,.94,.71,.92);
    transform: translateX(100%);
  }
}

.header__employee {
  display: flex;
  text-align: center;
  justify-content: center; 
  align-items: center;
  height: 100%;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-left: 5.4rem;
  background-color: $color-lightGreen;

  &:hover {
    opacity: 0.8;
  }
}

.header__employee__link {
  &:hover {
    text-decoration: none;
  }
}

.header__employee__icon {
  height: 2.4rem;
}

.header__employee__title {
  color: $color-white;
  font-size: 1.3rem;

  margin-top: 1.6rem;
}

.header__menu__mobile {
  display: none;
  height: 100%;
  padding: 0 16px;
  background-color: $color-lightGreen;

  @include media('<=phone') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.header__menu__mobile__icon {
  fill: $color-white;
  width: 32px;
}

.header__menu__mobile__text {
  margin-top: 4px;
  color: white;
  font-size: 11px;
  font-weight: 700;
}

.header__menu__mobile__open {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 101vh;
  padding: 100px 16px;

  @include media('<=phone') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $color-lightGreen;
  }
  z-index: 9999;
}

.header__menu__mobile__open__menu {
  width: 100%;
  height: 100%;
}

.header__menu__mobile__close {
  position: absolute;
  top: 12px;
  right: 17px;
  fill: $color-white;
  width: 32px;
}

.header__menu__mobile__open__menu__item {
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);

  &:first-child {
    margin-top: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
  }
}

.header__menu__mobile__open__menu__item__link {
  font-size: 16px;
  font-weight: 700;

  color: $color-white;
}

.header__menu__mobile__open__menu__item__lock-icon {
  width: 24px;
  height: 24px;
}

.overflow-hidden {
  overflow: hidden;
}

.active {
  color: $color-lightGreen;
}
