.business__hero {
  display:flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(51, 51, 51, 0.2) 0%,rgba(51, 51, 51, 0.2) 100%), url('../images/business_main.jpg');
  background-position: center;
  background-size: cover;

  @include media('<=phone') {
    height: 70vh;
  }
}

.business__hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.business__hero__main {
  @extend %page-title;
}

.business__hero__sub {
  @extend %page-title-caption;
}


// アンカーリンク
.business__menu {
  padding: 2.4rem 20rem;

  @include media('<=phone') {
    padding: 16px 16px;
  }
}

.business__menu__item {
  position: relative;
  padding: 0;
  transition: 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 6rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-black;
    opacity: 0.1;

    @include media('<=phone') {
      height: 24px;
    }
  }

  &:last-child {
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 6rem;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      background-color: $color-black;
      opacity: 0.1;
    }
  }

  &:hover {
    opacity: 0.6;
  }
}

.business__menu__item__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &:hover {
    text-decoration: none;
  }
}

.business__menu__item__number {
  font-size: 1.6rem;
  color: $color-lightGreen;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.business__menu__item__text {
  color: $color-black;
  margin-top: 1.2rem;
  font-size: 1.8rem;
  font-weight: 700;

  @include media('<=phone') {
    margin-top: 8px;
  }
}

.business__menu__item__icon {
  width: 2.4rem;
  margin-top: 0.8rem;

  @include media('<=phone') {
    width: 16px;
    margin-top: 4px;
  }
}

// メインコンテンツ
.business__main {
  width: 100%;
}

.business__main__construction {
  @extend %section;
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, $color-lightGray 50%,  $color-lightGray 100%);
}

.business__main__construction__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  @include media('<=phone') {
    padding: 0 16px;
  }
}

.business__main__construction__number {
  @extend %section-number;
}
.business__main__construction__text {
  @extend %section-title;
  margin-top: 1.6rem;
}

.business__main__construction__description {
  @extend %section-title-caption;
  font-size: 2rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.business__main__construction__contents {
  @extend %contents;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.business__main__construction__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;

  &:first-child :nth-child(3) {
    margin-left: 0;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 68%;
  }

  @include media('<=phone') {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.business__main__construction__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__construction__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: $color-white;

  @include media('<=phone') {
    width: 40%;
    height: 40px;
  }
}

.business__main__construction__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.business__main__plan {
  @extend %section;
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, $color-lightGray 50%,  $color-lightGray 100%);
}

.business__main__plan__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  @include media('<=phone') {
    padding: 0 16px;
  }
}

.business__main__plan__number {
  @extend %section-number;
}

.business__main__plan__text {
  @extend %section-title;
  margin-top: 1.6rem;
}

.business__main__plan__description {
  @extend %section-title-caption;
}

.business__main__plan__contents {
  @extend %contents;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.business__main__plan__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;

  &:first-child :nth-child(3) {
    margin-left: 0;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 78%;

    @include media('<=phone') {
      padding-top: 68%;
    }
  }

  @include media('<=phone') {
    margin-top: 30px;
    margin-left: 0;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.business__main__plan__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__plan__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: $color-white;

  @include media('<=phone') {
    width: 40%;
    height: 40px;
  }
}

.business__main__plan__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.business__main__measure {
  @extend %section;
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, $color-lightGray 50%,  $color-lightGray 100%);
}


.business__main__measure__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  @include media('<=phone') {
    padding: 0 16px;
  }
}

.business__main__measure__number {
  @extend %section-number;
}

.business__main__measure__text {
  @extend %section-title;
  margin-top: 1.6rem;
}

.business__main__measure__description {
  @extend %section-title-caption;
  font-size: 2rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.business__main__measure__contents {
  @extend %contents;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.business__main__measure__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;

  &:first-child :nth-child(3) {
    margin-left: 0;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 68%;
  }

  @include media('<=phone') {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.business__main__measure__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__measure__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: $color-white;

  @include media('<=phone') {
    width: 40%;
    height: 40px;
  }
}

.business__main__measure__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.business__main__inspect {
  @extend %section;
  text-align: center;
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, $color-lightGray 50%,  $color-lightGray 100%);
}

.business__main__inspect__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  @include media('<=phone') {
    padding: 0 16px;
  }
}

.business__main__inspect__number {
  @extend %section-number;
}


.business__main__inspect__text {
  @extend %section-title;
  margin-top: 1.6rem;
}

.business__main__inspect__description {
  @extend %section-title-caption;
}

.business__main__inspect__contents {
  @extend %contents;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.business__main__inspect__contents__item {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 2.4rem;

  &:first-child :nth-child(3) {
    margin-left: 0;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 75%;

    @include media('<=phone') {
      padding-top: 68%;
    }
  }

  @include media('<=phone') {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.business__main__inspect__contents__item__image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.business__main__inspect__contents__item__title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6rem;
  top: 0;
  left: 0;
  background-color: $color-white;

  @include media('<=phone') {
    width: 40%;
    height: 40px;
  }
}

.business__main__inspect__contents__item__text {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;

  @include media('<=phone') {
    font-size: 14px;
  }
}

.business__clients {
  @extend %section;
  text-align: center;
}

.business__clients__title {
  display: inline-block;
  position: relative;
  padding: 0 6rem;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-47%);
    background-color: $color-lightGreen;
    opacity: 0.3;
  }

  @include media('<=phone') {
    padding: 0 16px;
  }
}

.business__clients__number {
  @extend %section-number;
}

.business__clients__text {
  @extend %section-title;
  margin-top: 1.6rem;
}

.business__clients__list {
  width: 100%;
  margin-top: 4rem;

  @include media('<=phone') {
    margin: 30px 0 0 0;
  }
}

.business__clients__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include media('<=phone') {
    margin-left: 0;
    margin-top: 30px;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.business__clients__image {
  width: 40%;
  height: auto;
}

.business__clients__caption {
  margin-top: 5rem;
  font-size: 1.4rem;

  @include media('<=phone') {
    margin-top: 30px;
    font-size: 14px;
  }
}

.business__clients__others {
  margin-top: 6rem;
  font-size: 2rem;

  @include media('<=phone') {
    margin-top: 30px;
    font-size: 16px;
  }
}
